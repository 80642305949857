import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { copyToClipboard } from 'utilities';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';

// copy payment code and show toast
export const Notify = (data) => {
    const { t } = useTranslation();
    toast.info(t('copied_to_clipboard'), {
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    copyToClipboard(data);
};

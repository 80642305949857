import React from 'react';
import MidtransLogo from 'presentations/assets/images/midtrans.png';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { checkExpiredMonthAndYear } from 'utilities';

// Credit Card Form Component
function CreditCardFormSection({
    check,
    cardNumber,
    cardName,
    cardCvv,
    cardMonth,
    cardYear,
    onInputNumber,
    onInputName,
    onInputCvv,
    onInputMonth,
    onInputYear,
    errorMessage,
}) {
    const { t } = useTranslation();

    const isInvalidCardCVV = () => {
        return (cardCvv.length < 3 && check) || errorMessage.includes('card_cvv must be between 3 to 4 digits');
    };

    const isExpiredCard = () => {
        return (
            (checkExpiredMonthAndYear(cardMonth, cardYear) && check) ||
            errorMessage.includes(
                'card_exp_year and card_exp_month must be greater or equals to current year and month'
            )
        );
    };

    const isInvalidCardMonth = () => {
        return (
            (!cardMonth.match(/^(0[1-9]|1[0-2])$/) && check) ||
            errorMessage.includes('card_exp_month does not match with regular expression: (0[1-9])|(1[0-2])')
        );
    };

    const isInvalidCardYear = () => {
        return (cardYear.length < 4 && check) || errorMessage.includes('card_exp_year must be 4 digits');
    };

    return (
        <div className="bg-white px-4 pt-4 pb-6">
            <form className="lg:mt-4 mt-2">
                <div>
                    <label className="font-face-poppinsbold text-xs lg:text-sm">{t('credit_number')}</label>
                    <br />
                    <input
                        type="text"
                        placeholder={t('credit_number_placeholder')}
                        maxLength="19"
                        className="w-full border-b-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm bg-gray-textinput px-2 py-2 mt-2"
                        value={cardNumber}
                        onInput={onInputNumber}
                    />
                    {(cardNumber.length > 0 && cardNumber.length < 19 && check) ||
                    errorMessage.includes('card_number does not match with luhn algorithm') ? (
                        <p className="text-red-error text-xs lg:text-sm mt-2">{t('invalid_cc_number')}</p>
                    ) : null}
                    {cardNumber.length === 0 && check ? (
                        <p className="text-red-error text-xs lg:text-sm mt-2">{t('empty_cc_number')}</p>
                    ) : null}
                </div>
                <div className="lg:mt-12 mt-6">
                    <label className="font-face-poppinsbold text-xs lg:text-sm mt-6">{t('credit_name')}</label>
                    <br />
                    <input
                        type="text"
                        placeholder={t('credit_name_placeholder')}
                        className="w-full border-b-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm bg-gray-textinput px-2 py-2 mt-2"
                        value={cardName}
                        onInput={onInputName}
                    />
                    {cardName.length === 0 && check ? (
                        <p className="text-red-error text-xs lg:text-sm mt-2">{t('empty_name')}</p>
                    ) : null}
                </div>
                <div className="lg:flex lg:flex-row lg:justify-between">
                    <div className="lg:mt-12 mt-6 w-3/4 lg:w-1/2">
                        <label className="font-face-poppinsbold text-xs lg:text-sm mt-6">{t('credit_validity')}</label>
                        <br />
                        <div className="flex flex-row items-center">
                            <input
                                type="text"
                                className="w-full border-b-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm bg-gray-textinput px-2 py-2 mt-2 lg:w-1/3"
                                placeholder="mm"
                                maxLength="2"
                                value={cardMonth}
                                onInput={onInputMonth}
                            />
                            <p className="text-blue-dark_blue text-base mx-2">/</p>
                            <input
                                type="text"
                                className="w-full border-b-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm bg-gray-textinput px-2 py-2 mt-2 lg:w-1/3"
                                placeholder="yyyy"
                                maxLength="4"
                                value={cardYear}
                                onInput={onInputYear}
                            />
                        </div>
                    </div>
                    <div className="lg:mt-12 mt-6 w-3/4 lg:w-1/2">
                        <label className="font-face-poppinsbold text-xs lg:text-sm mt-6">CVV</label>
                        <br />
                        <input
                            type="text"
                            className="w-full border-b-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm bg-gray-textinput px-2 py-2 mt-2 lg:w-3/4"
                            placeholder={t('cvv_example')}
                            maxLength="4"
                            value={cardCvv}
                            onInput={onInputCvv}
                        />
                        <p className="text-gray-cvv text-xs lg:text-sm mt-2">{t('cvv_instruction')}</p>
                    </div>
                </div>
                {/* check and show error if card cvv not valid */}
                {isInvalidCardCVV() ? (
                    <p className="text-red-error text-xs lg:text-sm mt-2 lg:mt-4">{t('cvv_validity_error')}</p>
                ) : null}
                {/* check and show error if card expiry not valid */}
                {isExpiredCard() ? (
                    <p className="text-red-error text-xs lg:text-sm mt-2 lg:mt-4">{t('expired_error')}</p>
                ) : null}
                {/* check and show error if card month not valid */}
                {isInvalidCardMonth() ? (
                    <p className="text-red-error text-xs lg:text-sm mt-2 lg:mt-4">{t('exp_month_error')}</p>
                ) : null}
                {/* check and show error if card year not valid */}
                {isInvalidCardYear() ? (
                    <p className="text-red-error text-xs lg:text-sm mt-2 lg:mt-4">{t('exp_year_error')}</p>
                ) : null}
            </form>
            <div className="mt-10 flex flex-row items-center">
                <p className="text-gray-cvv text-powered mr-2">{t('powered_by')}</p>
                <img src={MidtransLogo} alt="midtrans" className="max-h-4" />
            </div>
        </div>
    );
}

export default CreditCardFormSection;

import React, { useState } from 'react';
import 'presentations/assets/css/app.css';
import Collapse from '@mui/material/Collapse';
import { ProductItem } from 'presentations/shared';
import { KeyboardIcon } from './ComponentPage';

function ProductDetail({ items }) {
    const [checked, setChecked] = useState(true);

    const handleCollapse = () => {
        setChecked((prev) => !prev);
    };

    return (
        <>
            <button className="w-full" onClick={() => handleCollapse()}>
                <div className="bg-white items-center px-5 py-3 flex flex-row justify-between">
                    <p className="font-face-poppinsbold text-left text-xs lg:text-sm">{items.item_name}</p>
                    {items.additional_detail ? <KeyboardIcon checked={checked} /> : null}
                </div>
            </button>

            {items.additional_detail ? (
                <Collapse in={checked}>
                    {items.additional_detail.map((item) => {
                        return (
                            <ProductItem key={item.value} icon={item.url_icon} category={item.key} value={item.value} />
                        );
                    })}
                </Collapse>
            ) : null}
        </>
    );
}

export default ProductDetail;

import React from 'react';
import { LoadingPage } from '../pages';

export default function LinkAjaWco({ auth, action }) {
    return (
        <div>
            <LoadingPage />
            <div className="hidden">
                <form name="linkajaform" id="linkajaform" action={action} method="post">
                    <input type="hidden" name="message" value={auth} />
                    <button
                        type="submit"
                        className="rounded-xl w-1/2 mx-auto lg:w-1/6 bg-red-tsel py-3 px-5 hover:bg-red-500 hidden"
                    >
                        <p className="text-white text-base font-face-poppinsbold mx-auto">Loading...</p>
                    </button>
                </form>
            </div>
        </div>
    );
}

import React from 'react';
import Loading from '../../../assets/images/loading_tsel.png';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';

// main component of loading page with image and text
function LoadingHero() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col md:w-100 items-center lg:mt-0 w-full lg:w-full md:mr-5 h-screen place-content-center mx-auto">
            <div className="h-48 lg:h-88 mx-auto mt-4 lg:mt-4 ">
                <img src={Loading} alt="Loading" className="h-48 lg:h-88" />
            </div>
            <p className="mt-4 text-2xl lg:text-3xl font-face-poppinsbold text-center">{t('loading_1')}</p>
            <p className="mt-1 text-sm lg:text-base font-face-poppinsregular text-center lg:mt-2">{t('loading_2')}</p>
        </div>
    );
}

export default LoadingHero;

import React from 'react';
import Failed from 'presentations/assets/images/failed.png';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';

function ErrorHero() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col md:w-100 items-center lg:mt-0 w-full h-auto lg:w-full md:mr-5 place-content-center mx-auto">
            <div className="h-36 w-36 lg:h-88 lg:w-88 mx-auto mt-4 lg:mt-4">
                <img src={Failed} alt="Failed" />
            </div>
            <p className="mt-4 text-2xl lg:text-3xl font-face-poppinsbold text-center">{t('failed_1')}</p>
            <p className="mt-1 text-sm lg:text-base font-face-poppinsregular text-center lg:mt-2">{t('failed_2')}</p>
        </div>
    );
}

export default ErrorHero;

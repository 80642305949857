import React from 'react';
import LogoGPN from 'presentations/assets/images/logogpn.png';
import LogoQRIS from 'presentations/assets/images/logoqris.png';
import Download from 'presentations/assets/images/download.png';
import Share from 'presentations/assets/images/union.png';
import { useTranslation } from 'react-i18next';

export default function QRISSection({ url, onClick, isInbox }) {
    const { t } = useTranslation();
    return (
        <div className="h-auto w-full bg-white mt-3 p-6">
            <div className="flex flex-row justify-between">
                <img src={LogoQRIS} alt="Logo QRIS" className=" max-h-14 lg:max-h-14" />
                <img src={LogoGPN} alt="Logo GPN" className="max-h-14 lg:max-h-14" />
            </div>
            <div className="h-auto w-full px-12 lg:px-47">
                <img src={url} alt="QRIS" className="mx-auto max-h-54 lg:max-h-80" />
            </div>
            <div className="mt-3 flex flex-row px-16 lg:w-1/2 lg:mx-auto">
                <div className={`flex ${isInbox ? 'w-full text-center' : 'w-1/2 border-r-2 border-blue-dark_blue'}`}>
                    <a href={url} target="_blank" rel="noopener noreferrer" download className="flex flex-row mx-auto">
                        <div className="mr-2 m-auto">
                            <img src={Download} alt="Download" className="max-h-4" />
                        </div>
                        <p className="font-face-poppinsbold text-link_download text-blue-link">{t('download')}</p>
                    </a>
                </div>
                {!isInbox ? (
                    <div className="flex w-1/2">
                        <button className="flex flex-row mx-auto" onClick={onClick}>
                            <div className="mr-2 m-auto">
                                <img src={Share} alt="Share" className="max-h-3" />
                            </div>
                            <p className="font-face-poppinsbold text-link_download text-blue-link">{t('share')}</p>
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

import React from 'react';
import { LoadingHero } from './Components';

function LoadingPage() {
    return (
        <div className="flex flex-grow h-full md:h-full lg:h-screen bg-gray-200 w-full z-100">
            <div className="sm:h-full w-full md:h-full lg:px-40 md:px-28 px-4 place-content-center">
                <LoadingHero />
            </div>
        </div>
    );
}

export default LoadingPage;

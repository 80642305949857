import React from 'react';
import useViewModel from './FailedBindingPageViewModel';
import BindingInfo from 'presentations/shared/BindingInfo';

// shown if binding process failed, redirected from redirectpage
export default function FailedBindingPage() {
    const { goToPreviousPath, redirectToPaymentPage, params } = useViewModel();

    return (
        <BindingInfo
            goToPreviousPath={goToPreviousPath}
            redirectToPaymentPage={redirectToPaymentPage}
            params={params}
            type={'failed'}
        />
    );
}

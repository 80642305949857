import React from 'react';
import { MerchantLogo } from './ComponentPage';

function MerchantInfo({ channel, text }) {
    return (
        <div>
            <p className="text-partner text-gray-hero_title font-face-poppinsbold">
                {text} {channel}
            </p>
            <div className="mt-7 p-1 mb-5">
                {/* <img src={channel === 'Indomaret' ? IndomaretLogo : AlfamartLogo} alt="logo" className="mx-auto w-20" /> */}
                <MerchantLogo channel={channel} />
            </div>
        </div>
    );
}

export default MerchantInfo;

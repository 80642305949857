import React from 'react';
import { Instruction } from './Instruction';

function PaymentCodeInstructionSection({ payment_info, partner, show, handleCollapse }) {
    return (
        <>
            <Instruction payment_info={payment_info} partner={partner} show={show} handleCollapse={handleCollapse} />
        </>
    );
}

export default PaymentCodeInstructionSection;

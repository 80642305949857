import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { BRI_DD, BNI_DD, GOPAY, MANDIRI_DD, XENDIT_WALLET, SHOPEEPAY } from 'utilities';

// unbinding confirmation modal
function ModalUnbind({ partner, onUnbind, onClose }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full mx-auto lg:max-w-none">
                    {/*content*/}
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="rounded-xl h-0.5 w-24 bg-gray-pull mx-auto lg:hidden hover:bg-gray-700"
                            onClick={onClose}
                        />
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto mt-6">
                            <p className="text-base font-face-tselbold">
                                {t('remove')} {partner}
                                {t('delete_confirmation')}
                            </p>
                            <p className="mt-4 text-xs font-face-poppinsregular">{t('unbind_wording')}</p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center flex-col mt-6">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onUnbind}
                            >
                                {t('remove')}
                            </button>
                            <button
                                className="border-red-tsel border mt-0.75 w-full text-red-tsel active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onClose}
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-blue-dark_blue" onClick={onClose}></div>
        </>
    );
}

export default ModalUnbind;

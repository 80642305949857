import React from 'react';
import useViewModel from './InboxPaymentCodePageViewModel';
import {
    PaymentCountDown,
    QRISSection,
    QRISPaymentPartner,
    MerchantBarcodeInfo,
    TotalPrice,
} from 'presentations/shared';
import { QRIS } from 'utilities';
import { useTranslation } from 'react-i18next';

function Code(props) {
    const {
        data,
        notify,
        partner,
        time: { hours, minutes, seconds },
    } = props;
    const { t } = useTranslation();
    const isQRIS = () => {
        return data.payment_info && data.payment_info.payment_provider === QRIS;
    };

    return (
        <>
            {isQRIS() ? (
                <div className="mb-3">
                    <QRISSection url={data.payment_info.qr_url} isInbox={true} />
                    <div className="mt-3">
                        <PaymentCountDown
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                            text={t('complete_the_payment')}
                        />
                    </div>
                    <QRISPaymentPartner text={t('pay_with')} />
                </div>
            ) : (
                <MerchantBarcodeInfo
                    total={data.item_details ? data.item_details.item_price : 0}
                    channel={partner}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    notify={notify}
                    code={data.payment_info ? data.payment_info?.payment_code : '123456789'}
                    fee={data.item_details ? data.item_details.item_fee : 0}
                />
            )}
        </>
    );
}

export default Code;

import React from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';

function BoxInfo(props) {
    const text = props;
    return (
        <div className="rounded h-auto bg-orange-sunset flex flex-row p-3 items-center">
            <div>
                <InfoIcon style={{ color: '#FE6E00' }} />
            </div>
            <div className="ml-2">{<p className="text-box_info font-face-poppinsregular">{text.text}</p>}</div>
        </div>
    );
}

export default BoxInfo;

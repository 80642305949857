import React from 'react';
import LinkAja from 'presentations/assets/images/ewallet/link-aja.png';
import Shopee from 'presentations/assets/images/ewallet/shopee-pay.png';
import Dana from 'presentations/assets/images/atom/icon/system/24/dana.png';
import Gopay from 'presentations/assets/images/atom/icon/system/24/gopay.png';
import OVO from 'presentations/assets/images/atom/icon/system/24/ovo.png';
import BCA from 'presentations/assets/images/BCA.png';

export default function QRISPaymentPartner({ text }) {
    return (
        <div className="h-auto w-full px-4 py-3 mt-3 bg-white flex flex-row">
            <div className="w-1/3 lg:w-1/3 flex items-center">
                <p className="text-link_download font-face-poppinsregular text-gray-hero_title">{text}</p>
            </div>
            <div className="w-2/3 lg:w-2/3 flex flex-row justify-end">
                <img src={LinkAja} alt="LinkAja" className="max-h-6 mr-1" />
                <img src={Gopay} alt="Gopay" className="max-h-6 mr-1" />
                <img src={BCA} alt="BCA" className="max-h-6 mr-1" />
                <img src={Shopee} alt="Shopee" className="max-h-6 mr-1" />
                <img src={OVO} alt="OVO" className="max-h-6 mr-1" />
                <img src={Dana} alt="Dana" className="max-h-6 mr-1" />
            </div>
        </div>
    );
}

import React from 'react';
import { price } from 'utilities';

export const TotalPrice = (total, itemFee) => {
    // const { total, itemFee } = props;

    console.log('cek harga ' + total);
    console.log(itemFee);
    if (itemFee.admin_fee_value) {
        return itemFee && itemFee.admin_fee_type === 'fixed'
            ? price(total + itemFee.admin_fee_value)
            : price(total + total * (itemFee.admin_fee_value / 100));
    } else {
        return price(total);
    }
};

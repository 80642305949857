import React from 'react';
import Portal from 'presentations/assets/images/portal.png';
import Dot from 'presentations/assets/images/dot.png';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { ModalBindingOfferLogo } from './ModalBindingOfferLogo';
import { GOPAY } from 'utilities';

// modal offer binding or continue wco/jump app for dana and gopay
function ModalBindingOffer({ partner, onBind, onClose, onContinue }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full mx-auto lg:max-w-none">
                    {/*content*/}
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="rounded-xl h-0.5 w-24 bg-gray-pull mx-auto lg:hidden hover:bg-gray-700"
                            onClick={onClose}
                        />
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*header*/}
                        <div className="flex flex-row items-center justify-center p-10 rounded-t">
                            <img src={Portal} width="58.8" height="80" className="mr-1" alt="logo_tsel" />
                            <img src={Dot} height="8" width="8" alt="dot" className="mr-1 ml-1" />
                            <img src={Dot} height="8" width="8" alt="dot" className="mr-1 ml-1" />
                            <img src={Dot} height="8" width="8" alt="dot" className="mr-1 ml-1" />
                            <ModalBindingOfferLogo partner={partner} />
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto">
                            <p className="text-base font-face-tselbold">
                                {t('binding_wording_1')}
                                {partner}
                            </p>
                            <p className="mt-4 text-xs font-face-poppinsregular">
                                Hubungkan akun <span>{partner}</span> kamu supaya transaksi jadi lebih mudah
                                {t('binding_wording_2')}
                            </p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center flex-col mt-6">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onBind}
                            >
                                {t('binding_button')}
                                {partner}
                            </button>
                            <button
                                className="border-red-tsel border mt-0.75 w-full text-red-tsel active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onContinue}
                            >
                                {t('continue_without_binding')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-blue-dark_blue" onClick={onClose}></div>
        </>
    );
}

export default ModalBindingOffer;

import React from 'react';

function ProductItem({ category, value, icon }) {
    return (
        <div className="px-5 bg-white">
            <div className="py-3 flex flex-row justify-between items-center border-b-2 border-opacity-70 bg-white">
                <div className="flex-row flex items-center h-auto">
                    {icon !== '' ? (
                        <div className="max-h-5 w-15 flex-none items-center ">
                            <img src={icon} alt="Logo" className=" h-5 w-5 mx-auto" />
                        </div>
                    ) : (
                        <div className="max-h-5 w-5 flex-none items-center ">
                            <div className="h-5 w-5 bg-white"></div>
                        </div>
                    )}
                </div>
                <div className=" flex flex-grow flex-row justify-between ml-3.5">
                    <p className="text-xs font-face-poppinslight text-gray-hero_title">{category}</p>
                    <p className="text-xs font-face-poppinslight text-gray-hero_title">{value}</p>
                </div>
            </div>
        </div>
    );
}

export default ProductItem;

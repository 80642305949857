import React from 'react';
import useViewModel from './VoucherPageViewModel';
import { TopBar } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import { VoucherCard } from './Components';

function VoucherPage({ navigate, body, selectPromo, showBind, changePayment }) {
    const { t } = useTranslation();
    const { getPromo, total_price, promo, VoucherEmpty } = useViewModel();

    return (
        <>
            <div className="flex flex-col flex-grow h-screen w-full bg-white lg:px-72">
                <TopBar text={t('promo')} onClick={() => navigate(false)} />
                <div className="flex flex-col flex-grow items-center mt-3 pb-8">
                    {getPromo(body.payment_promo)?.usable?.map((data, index) => {
                        return (
                            <VoucherCard
                                key={data.promotion_id}
                                usable={true}
                                data={data}
                                onSelectedVoucher={() => selectPromo(data.promotion_id)}
                                promo={promo}
                            />
                        );
                    })}
                    {getPromo(body.payment_promo)?.unUsable?.length > 0 && (
                        <>
                            <h3 className="self-start font-face-poppinsreguler font-medium text-center text-md mt-5 ml-6">
                                {t('promo_not_usable')}
                            </h3>
                            {getPromo(body.payment_promo)?.unUsable.map((data) => {
                                return (
                                    <VoucherCard
                                        key={data.promotion_id}
                                        usable={false}
                                        data={data}
                                        total={total_price}
                                    />
                                );
                            })}
                        </>
                    )}
                    <VoucherEmpty body={body} showBind={showBind} changePayment={changePayment} />
                </div>
            </div>
        </>
    );
}

export default VoucherPage;

import React from 'react';
import DanaLogo from 'presentations/assets/images/dana.png';
import GopayLogo from 'presentations/assets/images/gopay.png';
import LinkAjaLogo from 'presentations/assets/images/ewallet/link-aja.png';
import ShopeePayLogo from 'presentations/assets/images/ewallet/shopee-pay.png';
import OVOLogo from 'presentations/assets/images/ovo.png';
import QRISLogo from 'presentations/assets/images/logoqris.png';
import AlfamartLogo from 'presentations/assets/images/alfamart.png';
import IndomaretLogo from 'presentations/assets/images/indomaret.png';
import AkulakuLogo from 'presentations/assets/images/akulaku.jpg';
import KredivoLogo from 'presentations/assets/images/kredivo.jpg';
import CreditCardLogo from 'presentations/assets/images/card.png';
import BNILogo from 'presentations/assets/images/bank/bni.png';
import BCALogo from 'presentations/assets/images/bank/bca_new.png';
import PermataLogo from 'presentations/assets/images/bank/permata.png';

import BRILogo from 'presentations/assets/images/bri.png';
import MandiriLogo from 'presentations/assets/images/bank/mandiri.png';
import CIMBLogo from 'presentations/assets/images/bank/cimb.png';
import MuamalatLogo from 'presentations/assets/images/bank/muamalat.png';
import BjbLogo from 'presentations/assets/images/bank/bjb-va.png';
import BtnLogo from 'presentations/assets/images/bank/va-btn.png';
import BsiLogo from 'presentations/assets/images/bank/va-bsi.png';
import BncLogo from 'presentations/assets/images/bank/va-bnc.png';
import NagariLogo from 'presentations/assets/images/bank/va-nagari.png';
import MegaLogo from 'presentations/assets/images/bank/va-mega.png';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
    AKULAKU,
    ALFAMART,
    BCA_DD,
    BJB,
    BNC,
    BNI_DD,
    BRI_DD,
    BSI,
    BTN,
    CREDIT_CARD,
    DANA,
    FINNET_CC,
    GOPAY,
    INDOMARET,
    KREDIVO,
    LINKAJA_APP,
    LINKAJA_WCO,
    MCP_CC,
    MEGA,
    NAGARI,
    OVO,
    QRIS,
    SHOPEEPAY,
    XENDIT_WALLET,
} from 'utilities';

export const MerchantLogo = (props) => {
    const { channel } = props;
    switch (channel) {
        case 'Alfamart':
            return <img src={AlfamartLogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'Indomaret':
            return <img src={IndomaretLogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'MANDIRI':
            return <img src={MandiriLogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'BNI':
            return <img src={BNILogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'BRI':
            return <img src={BRILogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'BCA':
            return <img src={BCALogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        case 'PERMATA':
            return <img src={PermataLogo} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
        default:
            return <div className="max-h-10 lg:max-h-7 w-auto bg-white" />;
    }
};

export const Title = (props) => {
    const { partner, text } = props;
    switch (partner) {
        case OVO:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">OVO</p>;
        case LINKAJA_APP:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">LinkAja</p>;
        case LINKAJA_WCO:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">LinkAja</p>;
        case SHOPEEPAY:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">ShopeePay</p>;
        case GOPAY:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">Gopay</p>;
        case DANA:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">DANA</p>;
        default:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">{text}</p>;
    }
};

export const HeaderLogo = (props) => {
    const { partnerlogo } = props;
    console.log('partnerlogo' + partnerlogo);
    switch (partnerlogo) {
        case OVO:
            return <img src={OVOLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case 'ovo_binding':
            return <img src={OVOLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case LINKAJA_APP:
            return <img src={LinkAjaLogo} alt="Logo" className="max-h-10 lg:max-h-14 w-auto" />;
        case LINKAJA_WCO:
            return <img src={LinkAjaLogo} alt="Logo" className="max-h-10 lg:max-h-14 w-auto" />;
        case SHOPEEPAY:
            return <img src={ShopeePayLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case GOPAY:
            return <img src={GopayLogo} alt="Logo" className="max-h-10 lg:max-h-10 w-10" />;
        case DANA:
            return <img src={DanaLogo} alt="Logo" className="max-h-10 lg:max-h-10 w-auto" />;
        case QRIS:
            return <img src={QRISLogo} alt="Logo" className="max-h-10 lg:w-1/4 lg:h-full xl:w-1/4 xl:h-full w-auto" />;
        case ALFAMART:
            return <img src={AlfamartLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case INDOMARET:
            return <img src={IndomaretLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case AKULAKU:
            return <img src={AkulakuLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case KREDIVO:
            return <img src={KredivoLogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
        case FINNET_CC:
            return <img src={CreditCardLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case CREDIT_CARD:
            return <img src={CreditCardLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case MCP_CC:
            return <img src={CreditCardLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case 'mandiri':
            return <img src={MandiriLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case 'bni':
            return <img src={BNILogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BNI_DD:
            return <img src={BNILogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case 'bri':
            return <img src={BRILogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BRI_DD:
            return <img src={BRILogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BCA_DD:
        case 'bca':
            return <img src={BCALogo} alt="Logo" className="max-h-14 lg:max-h-20 w-auto" />;
        case 'permata':
            return <img src={PermataLogo} alt="Logo" className="max-h-14 lg:max-h-20 w-auto" />;
        case 'cimb':
            return <img src={CIMBLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case 'muamalat':
            return <img src={MuamalatLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BSI:
            return <img src={BsiLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BTN:
            return <img src={BtnLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BNC:
            return <img src={BncLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case BJB:
            return <img src={BjbLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case NAGARI:
            return <img src={NagariLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        case MEGA:
            return <img src={MegaLogo} alt="Logo" className="max-h-8 lg:max-h-10 w-auto" />;
        default:
            return <div className="max-h-10 lg:max-h-7 w-auto bg-white" />;
    }
};

export const KeyboardIcon = (props) => {
    const { checked } = props;
    return checked ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />;
};

import React from 'react';
import useViewModel from './BindingDDViewModel';
import { ModalAlert, PartnerHeader, TopBar } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import OtpPage from '../directdebit-page/OtpPage';
import { BNI_DD, cc_format } from 'utilities';

export default function BindingDDPage() {
    const { t } = useTranslation();

    const {
        partner,
        goToPreviousPath,
        stateGlobal,
        showOtp,
        setShowOtp,
        showModalAlert,
        showError,
        setShowModalAlert,
        payment_info,
        setDataDirectDebit,
        dataDirectDebit,
        initiateBinding,
        modalWording,
    } = useViewModel();

    const handleOtp = (alertValue) => {
        setShowModalAlert(alertValue);
    };

    return (
        <>
            <div className="flex flex-col flex-grow h-screen bg-gray-200 lg:px-72 xl:px-98">
                <TopBar partner={partner} text={t('addnewcard')} onClick={goToPreviousPath} />
                <div className="h-screen flex flex-col w-full justify-between">
                    <div className="bg-gray-200">
                        <div className="px-8 lg:px-20 py-4 lg:pb-6 bg-white">
                            <PartnerHeader channel={stateGlobal.channel} partnerlogo={payment_info.payment_provider} />
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BNI_DD ? t('confirmationbniinstruction') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                1. {payment_info.payment_provider === BNI_DD ? t('key1bniinstruction') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                2. {payment_info.payment_provider === BNI_DD ? t('key2bniinstruction') : ''}
                            </p>
                        </div>
                        <div className="px-8 lg:px-20 py-4 bg-white mt-2 lg:mt-4 mb-4">
                            {/* <PhoneNumberForm
                        customer_info={customer_info}
                        partner={payment_info.payment_provider}
                        value={number}
                        onInput={(e) => setNumber(e.target.value)}
                        input={t('gopayinput')}
                        placeholder={t('gopayphoneplaceholder')}
                        account={t('gopayaccount')}
                    /> */}
                            <form className="lg:mt-4 mt-2">
                                <div>
                                    <label className="font-face-poppinsbold text-xs lg:text-sm">
                                        {t('bni_account_number')}
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder={t('account_bni_placeholder')}
                                        maxLength="10"
                                        className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2"
                                        onChange={(e) =>
                                            setDataDirectDebit({
                                                ...dataDirectDebit,
                                                account_no: e.target.value.replace(/[^0-9]/g, ''),
                                            })
                                        }
                                        value={dataDirectDebit.account_no}
                                    />
                                    {dataDirectDebit.account_no.length > 0 && dataDirectDebit.account_no.length < 10 ? (
                                        <p className="text-red-error text-xs lg:text-sm mt-2">
                                            {t('invalid_account_number')}
                                        </p>
                                    ) : null}
                                    {/* {(cardNumber.length > 0 && cardNumber.length < 19 && check) ||
                            errorMessage.includes('card_number does not match with luhn algorithm') ? (
                                <p className="text-red-error text-xs lg:text-sm mt-2">
                                    {t('invalid_cc_number')}
                                </p>
                            ) : null}
                            {cardNumber.length === 0 && check ? (
                                <p className="text-red-error text-xs lg:text-sm mt-2">
                                    {t('empty_cc_number')}
                                </p>
                            ) : null} */}
                                </div>

                                {/* <div className="lg:flex lg:flex-row lg:justify-between"> */}
                                {/* <div className="lg:mt-12 mt-6 w-3/4 lg:w-1/2"> */}
                                <div>
                                    <label className="font-face-poppinsbold text-xs lg:text-sm">
                                        {t('bni_card_number')}
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder={t('card_bni_placeholder')}
                                        maxLength="4"
                                        className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2"
                                        onChange={(e) =>
                                            setDataDirectDebit({
                                                ...dataDirectDebit,
                                                card_number: e.target.value.replace(/[^0-9]/g, ''),
                                            })
                                        }
                                        value={dataDirectDebit.card_number}
                                    />
                                    {dataDirectDebit.card_number.length > 0 &&
                                    dataDirectDebit.card_number.length < 4 ? (
                                        <p className="text-red-error text-xs lg:text-sm mt-2">
                                            {t('invalid_cc_number')}
                                        </p>
                                    ) : null}
                                </div>
                            </form>
                            <div className="bg-white h-22 w-full py-5 px-4 lg:px-20">
                                <button
                                    type="submit"
                                    className="rounded-3xl w-full mx-auto flex bg-red-tsel py-4 lg:py-5 px-5 hover:bg-red-500 disabled:opacity-50 disabled:bg-red-tsel"
                                    onClick={initiateBinding}
                                    disabled={
                                        dataDirectDebit.card_number === '' || dataDirectDebit.account_no === ''
                                            ? true
                                            : showError
                                    }
                                >
                                    <p className="text-white text-button font-face-poppinsbold mx-auto">
                                        {t('add_card')}
                                    </p>
                                </button>
                            </div>
                            {/* <AccountNotFoundNotification showError={showError} t={t} /> */}
                        </div>
                    </div>
                </div>
            </div>
            {showOtp && (
                <OtpPage
                    title_text={t('invalid_data')}
                    text={t('warningdata')}
                    text_button="verifikasi"
                    onClick={() => setShowOtp(false)}
                    handleOtp={handleOtp}
                    type="binding"
                />
            )}

            {showModalAlert && (
                <ModalAlert
                    title_text={t(modalWording.modalTitle)}
                    text={t(modalWording.modalText)}
                    text_button={t(modalWording.modalButton)}
                    onClick={async () => {
                        await setShowModalAlert(false);
                        goToPreviousPath();
                    }}
                    img={modalWording.modalImg}
                />
            )}
        </>
    );
}

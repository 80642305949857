// check month and year expiry for credit card
export const checkExpiredMonthAndYear = (cardMonth, cardYear) => {
    let today, someday;
    let exMonth = cardMonth;
    let exYear = cardYear;
    today = new Date();
    someday = new Date();
    someday.setFullYear(exYear, exMonth, 1);

    return someday < today;
};

import { isStringValid } from './isStringValid';
import { checkFormatPhone, phoneNumber as phoneNumberFormatter } from './phoneNumber';
import { price } from './price';

export const totalPriceHandle = (inputBody, totalPrice) => {
    let totalTransaction = handleBody(inputBody, totalPrice).price;
    return totalTransaction.itemSelected ? totalTransaction.itemSelected : totalTransaction;
};

export const transformURLPartner = (inputBody, phoneNumber, bank, paymentId, totalPrice) => {
    let result = handleBody(inputBody, inputBody.item_details.item_price).price;
    let itemPrice = result.itemPrice ? result.itemPrice : result;
    let url = result.itemPrice ? result.url : '';

    let urlTransformed =
        `${inputBody.redirect_url_payment}/?partner_provider=${inputBody.payment_info.payment_provider}&transaction_id=${inputBody.transaction_id}&item_price=${itemPrice}` +
        url;

    if (isStringValid(bank)) urlTransformed += `&bank_name=${bank}`;
    if (isStringValid(phoneNumber)) {
        if(checkFormatPhone(phoneNumber)) {
            urlTransformed += `&payment_id=${phoneNumberFormatter(phoneNumber)}`;

        } else {
            urlTransformed += `&payment_id=62${phoneNumber}`;
        }
    }

    if (isStringValid(paymentId)) urlTransformed += `&payment_id=${paymentId}`;

    return urlTransformed;
};
export const handleBody = (inputBody, totalPrice) => {
    let price = '';

    if (
        inputBody.list_payment_partner.filter(
            (item) =>
                item.partner_provider === inputBody.payment_info.payment_provider &&
                (item.bank_name === inputBody.payment_info.bank_name ||
                    'binding' === inputBody.payment_info.bank_name ||
                    '' === inputBody.payment_info.bank_name ||
                    'gopay' === inputBody.payment_info.bank_name)
        ).length > 0
    ) {
        const bodySelected = inputBody.list_payment_partner.filter(
            (item) =>
                item.partner_provider === inputBody.payment_info.payment_provider &&
                (item.bank_name === inputBody.payment_info.bank_name ||
                    'binding' === inputBody.payment_info.bank_name ||
                    '' === inputBody.payment_info.bank_name ||
                    'gopay' === inputBody.payment_info.bank_name)
        );
        console.log('==list==');
        console.log(bodySelected[0]);
        price = itemPriceHandle(bodySelected[0], totalPrice);
    } else if (
        inputBody.recommendation_payment.filter(
            (item) =>
                item.partner_provider === inputBody.payment_info.payment_provider &&
                (item.bank_name === inputBody.payment_info.bank_name ||
                    'binding' === inputBody.payment_info.bank_name ||
                    '' === inputBody.payment_info.bank_name ||
                    'gopay' === inputBody.payment_info.bank_name)
        ).length > 0
    ) {
        const bodySelected = inputBody.recommendation_payment.filter(
            (item) =>
                item.partner_provider === inputBody.payment_info.payment_provider &&
                (item.bank_name === inputBody.payment_info.bank_name ||
                    'binding' === inputBody.payment_info.bank_name ||
                    '' === inputBody.payment_info.bank_name ||
                    'gopay' === inputBody.payment_info.bank_name)
        );
        console.log('=recom=');
        console.log(bodySelected[0]);
        price = itemPriceHandle(bodySelected[0], totalPrice);
    } else if (
        'balance' === inputBody.payment_info.payment_provider ||
        inputBody.additional_external_payment.filter(
            (item) => item.partner_provider === inputBody.payment_info.payment_provider
        ).length > 0
    ) {
        console.log('my balance');
        price = totalPrice;
    }
    return { price };
};

export const itemPriceHandle = (inputBody, totalPrice) => {
    // console.log(';;;;;;uuuuu;;;;;;;');
    // console.log(inputBody);
    // console.log(';;;;;;;;;;;;;');
    let itemPrice = 0;
    let url = '';
    let itemSelected = '';
    if (inputBody.admin_fee && inputBody.admin_fee.is_admin_fee && inputBody.admin_fee.admin_fee_value !== 0) {
        if (inputBody.admin_fee.admin_fee_type === 'percentage') {
            console.log('itemPrice %');
            url = `&item_fee=${(totalPrice * inputBody.admin_fee.admin_fee_value) / 100}`;
            itemPrice = (totalPrice * inputBody.admin_fee.admin_fee_value) / 100 + totalPrice;
            itemSelected = inputBody.admin_fee;
        } else if (inputBody.admin_fee.admin_fee_type === 'fixed') {
            console.log('itemPrice fix');
            url = `&item_fee=${inputBody.admin_fee.admin_fee_value}`;
            itemPrice = inputBody.admin_fee.admin_fee_value + totalPrice;
            itemSelected = inputBody.admin_fee;
        }
    } else {
        itemPrice = totalPrice;
    }
    console.log('::' + itemPrice);

    return { itemPrice, url, itemSelected };
};

export const shareUrl = (token) => {
    let baseUrl = `https://payment.telkomsel.com/paymentCode`;
    if (isStringValid(token)) {
        baseUrl += `?session_id=${token}`;
    }
    return baseUrl;
};

import { TopBar } from 'presentations/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { MaintenanceHero } from './Components';
import useViewModel from './MaintenancePageViewModel';

// shown if no balance, list_bank, list_payment_partner, additional_external_partner on response
export default function MaintenancePage() {
    const { t } = useTranslation();
    const { goToPreviousPath, navigateBack, refreshPage } = useViewModel();

    return (
        <div className="flex-1 h-full lg:min-h-screen lg:h-full bg-gray-200 w-full">
            <TopBar text={t('title')} onClick={goToPreviousPath} />
            <div className="px-10 h-screen flex justify-items-center flex-col align-middle">
                <div className="m-auto flex flex-col items-center">
                    <div className="flex sm:h-full md:h-full lg:h-full bg-gray-200 w-full">
                        <div className="sm:h-full w-full md:h-full lg:px-40 md:px-28 place-content-center">
                            <MaintenanceHero />
                        </div>
                    </div>
                    <button
                        className="rounded-3xl bg-red-tsel mt-22 lg:mt-10 h-auto w-full lg:w-1/2 p-4 hover:bg-red-500"
                        onClick={() => {
                            refreshPage();
                        }}
                    >
                        <p className="text-white text-button font-face-poppinsbold">{t('reload')}</p>
                    </button>
                    <button
                        className="rounded-3xl  mt-2 lg:mt-2 h-auto w-full lg:w-1/2 p-4 "
                        onClick={() => {
                            navigateBack();
                        }}
                    >
                        <p className="text-blue-tselproduct text-button font-face-poppinsbold">{t('back')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import HeroVoucherNotFound from 'presentations/assets/images/voucher_not_found.svg';
import { useTranslation } from 'react-i18next';

export default function VoucherBinding() {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col justify-center items-center h-1/2">
                <img className="w-32" src={HeroVoucherNotFound} alt="Tidak ada Voucher" />
                <h3 className="font-face-tselbold mt-8 mx-auto text-center text-base">{t('promo_not_found')}</h3>
                <p className="font-face-poppinsregular font-normal text-center text-xs">{t('promo_not_found_2')}</p>
            </div>
        </>
    );
}

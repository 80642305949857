import React from 'react';
import { GOPAY, XENDIT_WALLET } from 'utilities';

export default function PhoneNumberForm({
    onInput,
    value,
    input,
    placeholder,
    account,
    partner,
    customer_info,
    onSubmit,
}) {
    const handleSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit();
        }
    };

    return (
        <div className="mt-2">
            <p className="font-face-poppinsbold text-lg">{account}</p>
            <p className="font-face-poppinsregular text-sm text-gray-600 mt-2">{input}</p>
            <p className="font-face-poppinsregular text-sm text-gray-400 mt-5">{placeholder}</p>
            <div className="flex flex-row w-full h-auto mt-3 mb-5 lg:w-1/2">
                <div className="h-auto w-1/12 pr-2 flex items-center lg:w-auto lg:pr-5">
                    <p className="font-face-poppinsregular ">+62</p>
                </div>
                <div className="h-auto w-11/12 pl-3 lg:pl-0">
                    <form className="" onKeyDown={handleSubmit}>
                        <label>
                            <input
                                type="number"
                                className="w-full border-b-2 focus:outline-none text-base"
                                value={value}
                                onInput={onInput}
                                onKeyDown={(e) => {
                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </label>
                    </form>
                </div>
            </div>
        </div>
    );
}

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MaintenancePageViewModel() {
    let navigate = useNavigate();
    const stateGlobal = useSelector((state) => state);

    const goToPreviousPath = () => {
        navigate(-1);
    };

    // refresh page action button
    function refreshPage() {
        window.location.reload();
    }

    // navigate back to previous page, based on device type
    function navigateBack() {
        if (stateGlobal.upp_data.device === 'Desktop') {
            goToPreviousPath();
        } else if (stateGlobal.upp_data.back_url !== '') {
            window.location.replace(stateGlobal.upp_data.back_url);
        } else {
            goToPreviousPath();
        }
    }

    return {
        goToPreviousPath,
        refreshPage,
        navigateBack,
    };
}

import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
    token: '',
    language: '',
    payment_info: {},
    body: {},
    item_detail: {},
    success_url: '',
    failed_url: '',
    loading: true,
    total_price: 0,
    device: '',
    channel: '',
    upp_type: '',
    transaction_id: '',
    client_key: '',
    is_error: false,
    back_url: '',
    home_url: '',
    promo: '',
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        storeToken: (state, action) => {
            state.token = action.payload.token;
            localStorage.setItem('upp_token', action.payload.token);
            Cookies.set('upp_token', action.payload.token);
        },
        setBodyAll: (state, action) => {
            state.body = { ...state.body, ...action.payload.body };
        },
        setLoadingState: (state, action) => {
            state.loading = action.payload.loading;
        },
        setErrorState: (state, action) => {
            state.is_error = action.payload.is_error;
        },
        setStaticState: (state, action) => {
            state.failed_url = action.payload.failed_url;
            state.success_url = action.payload.success_url;
            state.language = action.payload.language;
            state.item_detail = action.payload.item_detail;
            state.channel = action.payload.channel;
            state.upp_type = action.payload.upp_type;
            state.transaction_id = action.payload.transaction_id;
            state.client_key = action.payload.client_key;
            state.back_url = action.payload.back_url;
            state.home_url = action.payload.home_url;
            state.recharge_url = action.payload.recharge_url;
        },
        setPaymentInfo: (state, action) => {
            state.payment_info = { ...state.payment_info, ...action.payload.payment_info };
        },
        setLanguage: (state, action) => {
            state.language = action.payload.language;
        },
        setProductItem: (state, action) => {
            state.item_detail = action.payload.item_detail;
        },
        setTotalPrice: (state, action) => {
            state.total_price = action.payload.total_price;
        },
        storeDevice: (state, action) => {
            state.device = action.payload.device;
        },
        storeChannel: (state, action) => {
            state.channel = action.payload.channel;
        },
        setPromo: (state, action) => {
            state.promo = action.payload.promo;
        },
    },
});

export const {
    storeToken,
    setBodyAll,
    setLoadingState,
    setStaticState,
    setPaymentInfo,
    setProductItem,
    setLanguage,
    setTotalPrice,
    storeDevice,
    storeChannel,
    setErrorState,
    setPromo,
} = dataSlice.actions;

export default dataSlice.reducer;

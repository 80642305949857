import axios from 'axios';

// base axios function to post request to API
export const fetchApi = async (method, base, auth, path, body) => {
    try {
        const response = await axios({
            method,
            url: `${base}${path}`,
            headers: {
                Authorization: `Bearer ${auth}`,
            },
            data: body,
        });
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        const err = error;

        console.error(err.response.status);
        // return error;
        throw err.response;
    }
};

export const fetchApiGet = async (method, base, auth, path) => {
    try {
        const response = await axios({
            method,
            url: `${base}${path}`,
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        });
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        const err = error;

        console.error(err.response.status);
        // return error;
        throw err.response;
    }
};

import React, { useEffect } from 'react';
import Loading from '../../assets/images/loading_tsel.png';
import { useTranslation } from 'react-i18next';
import useViewModel from './RedirectPageViewModel';

// redirect page after perform binding, checking auth_code params from url and redirect to success or failed binding page
function RedirectPage() {
    const { t } = useTranslation();
    const { params, redirectPage } = useViewModel();

    useEffect(() => {
        if (params.channel) {
            redirectPage();
        }
    }, [params.channel, redirectPage]);

    return (
        <div className="flex flex-grow h-full md:h-full lg:h-screen bg-gray-200 w-full">
            <div className="sm:h-full w-full md:h-full lg:px-40 md:px-28 px-4 place-content-center">
                <div className="flex flex-col md:w-1/2 items-center lg:mt-0 w-full lg:w-full md:mr-5 h-screen place-content-center mx-auto">
                    <div className="h-48 lg:h-88 mx-auto mt-4 lg:mt-4 ">
                        <img src={Loading} alt="Loading" className="h-48 lg:h-88" />
                    </div>
                    <p className="mt-4 text-2xl lg:text-3xl font-face-poppinsbold text-center">{t('loading_1')}</p>
                    <p className="mt-1 text-sm lg:text-base font-face-poppinsregular text-center lg:mt-2">
                        {t('redirect')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default RedirectPage;

import { fetchApi } from 'utilities';

export async function requestOTP(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/auth/request_otp', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

export async function saveOtp(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/auth/saveOtp', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}


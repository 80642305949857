import React, { useState } from 'react';
// import { AGGREGATOR_PARTNER } from 'utilities';
import PaymentCategory from './PaymentCategory';
import { compareToRecommendation } from '../compareToRecommendation';
import PaymentPartner from './PaymentPartner';
import { useTranslation } from 'react-i18next';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';

const Section = (props) => {
    const {
        onChanged,
        data,
        recomendationData,
        selectedValue,
        onBind,
        body,
        onUnbind,
        checkBinding,
        sectionType,
        paymentType,
        token,
        last,
    } = props;
    const { t } = useTranslation();
    const [isExpand, setIsExpand] = useState(false);

    // console.log('ss---- ');
    // console.log(data);
    // console.log('ss----');
    // set selected partner
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    if (data.filter((word) => word.category.includes(sectionType)).length !== 0) {
        return (
            <div className={`flex flex-col px-3.5 rounded-2xl ${!last && `mb-4`}`}>
                {data.filter(
                    (word) =>
                        word.category === sectionType &&
                        word.method !== null &&
                        compareToRecommendation(recomendationData, word.display_name)
                ).length !== 0 ? (
                    <PaymentCategory type={t(paymentType)} isExpand={isExpand} setIsExpand={setIsExpand} />
                ) : null}
                {isExpand ? (
                    data
                        .filter((word) => word.category.includes(sectionType) && word.method !== null)
                        .map((itemType, index, array) => {
                            // console.log('now what select ' + selectedValue);
                            // console.log(itemType.partner_provider);
                            // console.log('select');
                            return (
                                <PaymentPartner
                                    last={array.length - 1 === index}
                                    key={index}
                                    items={itemType}
                                    token={token}
                                    isChecked={itemType.partner_provider === selectedValue.split('|')[0]}
                                    onBind={onBind}
                                    body={body}
                                    onUnbind={onUnbind}
                                    onCheckBinding={checkBinding}
                                    onChanged={controlProps(
                                        itemType.partner_provider + '|' + (itemType.bank_name ? itemType.bank_name : '')
                                    )}
                                />
                            );
                        })
                ) : (
                    <PaymentPartnerCollapse
                        data={data.filter((word) => word.category.includes(sectionType) && word.method !== null) || []}
                    />
                )}
            </div>
        );
    }
    return null;
};

export default Section;

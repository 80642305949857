import { submitPaymentToElisa } from 'domain/use-case';
import { Partners } from 'presentations/assets/JSONAsset';
import {
    AGGREGATOR_PARTNER,
    BCA_DD,
    BNI_DD,
    BRI_DD,
    CREDIT_CARD,
    FINNET_VA,
    MCP_VA,
    OVO,
    transformURLPartner,
    VIRTUAL_ACCOUNT,
    XENDIT_INVOICES,
} from 'utilities';
import { redirectPayment } from './redirectPayment';

// define payment flow, if payment partner is balance or from additional_external_payment => redirect to redirect_payment_url and append some params
// define static page / terms and condition page need to diplayed or not based on config on Partners JSON

export function submitOrder(body, external_payment, token, total, navigate, OTPCode, payment_id) {
    console.log('ga muncl');
    console.log(payment_id);
    const submitPayment = async () => {
        if (body.upp_type === 'indirect_with_order' && body.external_channel === false) {
            redirectToPaymentUrl();
        } else {
            try {
                const response = await submitPaymentToElisa(body.payment_info, token);
                console.log(response);
                if (response && response.status_code === '00') {
                    redirectPayment(response, body, navigate, total);
                } else {
                    console.log('did get res');
                    window.location.replace(body.redirect_url_failed);
                }
            } catch (e) {
                return e;
            }
        }
    };

    console.log(body);
    const redirectToPaymentUrl = () => {
        const paymentProvider = body.payment_info.payment_provider;
        const bankName = body.payment_info.bank_name;
        paymentProvider === VIRTUAL_ACCOUNT ||
        paymentProvider === FINNET_VA ||
        paymentProvider === MCP_VA ||
        paymentProvider === XENDIT_INVOICES
            ? window.location.replace(transformURLPartner(body, null, bankName, payment_id, total))
            : window.location.replace(transformURLPartner(body, null, bankName, payment_id, total));
    };

    switch (true) {
        case body.payment_info.payment_provider === 'mandiri_dd':
            body.payment_info.payment_otp = OTPCode;
            body.payment_info.payment_id = payment_id;
            body.payment_info.bank_name = 'binding';
            submitPayment();
            break;
        case body.payment_info.payment_provider === BRI_DD:
            body.payment_info.bank_name = 'binding';
            body.payment_info.payment_otp = OTPCode;
            body.payment_info.payment_id = payment_id;
            submitPayment();
            break;
        case body.payment_info.payment_provider === BNI_DD:
            body.payment_info.bank_name = 'binding';
            body.payment_info.payment_otp = OTPCode;
            body.payment_info.payment_id = payment_id;
            submitPayment();
            break;
        case body.payment_info.payment_provider === BCA_DD:
            body.payment_info.payment_otp = OTPCode;
            submitPayment();
            break;
        case body.payment_info.payment_provider === 'balance' ||
            external_payment.filter((word) => word.partner_provider === body.payment_info.payment_provider).length !==
                0:
            window.location.replace(transformURLPartner(body, null, null, null, total));
            break;
        case body.list_payment_partner.filter(
            (item) =>
                item.bank_name != undefined &&
                item.partner_provider === body.payment_info.payment_provider &&
                item.bank_name === body.payment_info.bank_name
        ).length !== 0 &&
            body.list_payment_partner.filter(
                (item) =>
                    item.partner_provider === body.payment_info.payment_provider &&
                    item.bank_name === body.payment_info.bank_name
            )[0].is_connecting_page:
            console.log('kkkk');
            navigate(`/payment/${body.payment_info.bank_name}`, {
                state: {
                    body: body,
                    token: token,
                    total: total,
                },
            });
            break;

        case (body.list_payment_partner.filter(
            (item) => item.partner_provider === body.payment_info.payment_provider && '' === body.payment_info.bank_name
        ).length !== 0 &&
            body.list_payment_partner.filter(
                (item) =>
                    item.partner_provider === body.payment_info.payment_provider && '' === body.payment_info.bank_name
            )[0].is_connecting_page) ||
            body.payment_info.payment_provider === OVO ||
            body.payment_info.bank_name === OVO ||
            body.payment_info.payment_provider === CREDIT_CARD:
            navigate(`/payment/${body.payment_info.payment_provider}`, {
                state: {
                    body: body,
                    token: token,
                    total: total,
                },
            });
            break;
        default:
            console.log('default');
            submitPayment();
            break;
    }
}

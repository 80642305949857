import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import PaymentCategory from './PaymentCategory';
import PaymentPartner from './PaymentPartner';
import Balance from '../../../assets/images/balance.svg';
import {
    CC,
    CREDITCARD,
    DIRECT_DEBIT,
    EMONEY,
    MERCHANT,
    PAYLATER,
    price,
    VA,
    Virtual_Account,
    CATEGORY,
    handleSelect,
} from 'utilities';
import Section from './Section';
import RecommendationSection from './RecommendationSection';
import ExternalPaymentSection from './ExternalPaymentSection';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';
import { PaymentPartnerLogo } from './PaymentPartnerLogo';

// list partner section of landing page
const PartnerSection = ({
    data,
    recomendationData,
    selectedValue,
    onDirectOpenBindingPage,
    onChanged,
    balance,
    token,
    body,
    msisdn_type,
    listBank,
    onBind,
    onUnbind,
    checkBinding,
    externalPayment,
    category,
    admin_fee_details,
    total,
    handleNavigate,
}) => {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);

    // set selected partner
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const totalPrice = (discount = 0) => {
        return admin_fee_details && admin_fee_details.admin_fee_type === 'fixed'
            ? price(total + admin_fee_details.admin_fee_value - discount)
            : price(total + total * (admin_fee_details.admin_fee_value / 100) - discount);
    };

    const msisdnType = () => {
        if (msisdn_type === 'postpaid') {
            return t('bill');
        } else {
            return t('balance');
        }
    };

    const ListBankSection = () => {
        if (listBank) {
            return (
                <div>
                    {listBank.length !== 0 ? <PaymentCategory type={t('va')} /> : null}
                    {listBank.map((item, index) => {
                        return (
                            <PaymentPartner
                                key={item.bank_name}
                                items={item}
                                token={token}
                                onCheckBinding={checkBinding}
                                onChanged={controlProps(item.bank_name)}
                            />
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="md:mb-0 lg:mb-0">
            {balance ? (
                <div className="px-3.5 py-3.5">
                    <PaymentCategory type="Telkomsel" isExpand={expand} setIsExpand={setExpand} />
                    {expand ? (
                        <PaymentPartner
                            balance_nominal={`${price(balance)}`}
                            logo={Balance}
                            desc={''}
                            items={{}}
                            body={body}
                            title={'balance'}
                            type={'Pulsa'}
                            onChanged={controlProps('balance')}
                            balance={msisdnType()}
                            token={token}
                            last={true}
                        />
                    ) : (
                        <PaymentPartnerCollapse isBalance={true} type={'Pulsa'} logo={Balance} />
                    )}
                </div>
            ) : null}
            {data.length !== 0 ? (
                <div>
                    <RecommendationSection
                        onChanged={onChanged}
                        recomendationData={recomendationData}
                        selectedValue={selectedValue}
                        token={token}
                        body={body}
                        onDirectOpenBindingPage={onDirectOpenBindingPage}
                        onBind={onBind}
                        onUnbind={onUnbind}
                        checkBinding={checkBinding}
                    />
                    {category.map((itemType, index, array) => {
                        return (
                            <Section
                                key={index}
                                onChanged={onChanged}
                                data={data}
                                recomendationData={recomendationData}
                                selectedValue={selectedValue}
                                onBind={onBind}
                                body={body}
                                onUnbind={onUnbind}
                                checkBinding={checkBinding}
                                sectionType={CATEGORY[itemType].section}
                                paymentType={CATEGORY[itemType].payment}
                                token={token}
                                last={array.length - 1 === index}
                            />
                        );
                    })}
                </div>
            ) : null}
            <ListBankSection />
            <ExternalPaymentSection
                externalPayment={externalPayment}
                VA={VA}
                CC={CC}
                onChanged={onChanged}
                selectedValue={selectedValue}
            />
            <div className="w-full px-4 hidden lg:flex md:flex justify-between">
                <p className="font-face-tselbold">{t('choose_payment')}</p>
                {/* <div>
                    {selectedValue !== '' ? (
                        <div>
                            <PaymentPartnerLogo
                                items={
                                    data.filter((word) =>
                                        word.payment_provider.includes(handleSelect(selectedValue))
                                    )[0]
                                }
                            />
                            <p className="font-face-tselbold">Gopay</p>
                        </div>
                    ) : (
                        <p className="font-face-tselbold">{t('choose_payment')}</p>
                    )}
                    <p className="font-face-poppinsbold text-total">{totalPrice()}</p>
                </div> */}
                <button
                    className="rounded-3xl w-1/2 bg-red-tsel mt-2 h-1/2 p-4 hover:bg-red-500"
                    onClick={() => handleNavigate()}
                >
                    <p className="text-white text-button font-face-poppinsbold">{t('paybutton')}</p>
                </button>
            </div>
        </div>
    );
};

export default PartnerSection;

import { price } from 'utilities';
import React from 'react';

function MerchantPaymentInfo({ total, channel, text_total, text_fee, fee, text_admin }) {
    return (
        <div className="flex flex-row w-full h-auto mt-6 justify-between">
            <div className="w-2/3">
                <p className="text-xs font-face-poppinsregular">{text_total}</p>
                {fee !== 0 ? (
                    <p className="text-link font-face-poppinsregular text-gray-separator">
                        {text_fee} {price(fee)} {text_admin} {channel}
                    </p>
                ) : null}
            </div>
            <div>
                <p className="font-face-poppinsbold text-base">Rp {price(total)}</p>
            </div>
        </div>
    );
}

export default MerchantPaymentInfo;

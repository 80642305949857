import { FinnetVAInstruction, MCPVAInstruction, MidtransVAInstruction } from 'presentations/assets/JSONAsset';
import React from 'react';
import { ALFAMART, FINNET_VA, INDOMARET, MCP_VA, VIRTUAL_ACCOUNT } from 'utilities';
import PaymentInstruction from './PaymentInstruction';
import VirtualAccountInstruction from './VirtualAccountInstruction';

const isVirtualAccount = (paymentInfo) => {
    console.log('paymkentInfo');
    console.log(paymentInfo);
    return (
        paymentInfo.payment_provider === VIRTUAL_ACCOUNT &&
        MidtransVAInstruction.data.detail.filter((item) => item.bank_name === paymentInfo.bank_name).length > 0
    );
};

const isFinnetVA = (payment_info) => {
    return (
        payment_info.payment_provider === FINNET_VA &&
        FinnetVAInstruction.data.detail.filter((item) => item.bank_name === payment_info.bank_name).length > 0
    );
};

const isMCPVA = (payment_info) => {
    return (
        payment_info.payment_provider === MCP_VA &&
        MCPVAInstruction.data.detail.filter((item) => item.bank_name === payment_info.bank_name).length > 0
    );
};

const isOfflineMerchant = (payment_info) => {
    return payment_info.payment_provider === ALFAMART || payment_info.payment_provider === INDOMARET;
};

export const Instruction = ({ payment_info, partner, show, handleCollapse }) => {
    console.log(payment_info);
    console.log('instrukkkkkk');
    if (isVirtualAccount(payment_info)) {
        return MidtransVAInstruction.data.detail
            .filter((item) => item.bank_name === payment_info.bank_name)
            .map((data, _index) =>
                data.instruction.map((items, index) => {
                    return (
                        <VirtualAccountInstruction
                            data={items.instruction_set}
                            key={index}
                            show={show}
                            handleCollapse={handleCollapse}
                        />
                    );
                })
            );
    } else if (isFinnetVA(payment_info)) {
        return FinnetVAInstruction.data.detail
            .filter((item) => item.bank_name === payment_info.bank_name)
            .map((data, _index) =>
                data.instruction.map((items, index) => {
                    return (
                        <VirtualAccountInstruction
                            data={items.instruction_set}
                            key={index}
                            show={show}
                            handleCollapse={handleCollapse}
                        />
                    );
                })
            );
    } else if (isMCPVA(payment_info)) {
        return MCPVAInstruction.data.detail
            .filter((item) => item.bank_name === payment_info.bank_name)
            .map((data, _index) =>
                data.instruction.map((items, index) => {
                    return (
                        <VirtualAccountInstruction
                            data={items.instruction_set}
                            key={index}
                            show={show}
                            handleCollapse={handleCollapse}
                        />
                    );
                })
            );
    } else if (isOfflineMerchant(payment_info)) {
        return (
            <PaymentInstruction
                name={partner}
                code={payment_info.payment_code}
                show={show}
                handleCollapse={handleCollapse}
            />
        );
    } else {
        return null;
    }
};

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setLoadingState } from 'adapters';

export default function ErrorPageViewModel() {
    const stateGlobal = useSelector((state) => state);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const goToPreviousPath = () => {
        navigate(-1);
    };

    // navigate based on device type
    function navigateBack() {
        if (stateGlobal.upp_data.device === 'Desktop') {
            goToPreviousPath();
        } else if (stateGlobal.upp_data.back_url !== '') {
            window.location.replace(stateGlobal.upp_data.back_url);
        } else {
            goToPreviousPath();
        }
    }

    useEffect(() => {
        dispatch(setLoadingState({ loading: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        goToPreviousPath,
        navigateBack,
    };
}

import { Header, PaymentTotal, ProductDetail, TopBar, ModalAlert } from 'presentations/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { cc_format } from 'utilities';
import { CreditCardFormSection } from './Components';
import ErrorPage from '../error-page';
import { useSelector } from 'react-redux';
import useViewModel from './CreditCardPageViewModel';

// Credit Card Page with Form
function CreditCardPage() {
    const { t } = useTranslation();
    const stateGlobal = useSelector((state) => state);
    const {
        error,
        app,
        goToPreviousPath,
        detail,
        total,
        submitToMidtrans,
        check,
        cardNumber,
        cardName,
        cardCvv,
        cardMonth,
        cardYear,
        setCardNumber,
        setCardName,
        setCardCvv,
        setCardMonth,
        setCardYear,
        errorMessage,
        showModalAlertError,
        setShowModalAlertError,
    } = useViewModel();

    // show error page or redirect to failed url
    if (error) {
        if (stateGlobal.upp_data.failed_url !== '') {
            window.location.replace(stateGlobal.upp_data.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    return (
        <>
            <div className="flex-1 h-screen lg:min-h-screen lg:h-full bg-gray-200 w-full">
                <div className="h-screen w-full lg:h-full lg:mb-0 mb-32 pt-17 lg:pt-18 lg:px-48 md:px-28 bg-gray-200">
                    {stateGlobal.upp_data.loading ? null : <Header channel={app} />}
                    <TopBar text={t('fill_credit_card_title')} onClick={goToPreviousPath} />
                    <div className="lg:w-2/5 md:w-1/2 lg:float-right md:float-right md:px-2 md:mt-4 lg:px-4 lg:mt-5 mt-1">
                        <ProductDetail items={detail} />
                        {stateGlobal.upp_data.loading ? null : (
                            <div>
                                <PaymentTotal
                                    order_details={t('order_details')}
                                    payment_details={t('payment_details')}
                                    type="credit_card"
                                    total={total}
                                    handleNavigate={submitToMidtrans}
                                    textButton={t('continuebutton')}
                                    word={t('total')}
                                />
                            </div>
                        )}
                    </div>
                    <div className="lg:w-3/5 md:w-1/2 md:overscroll-contain lg:overscroll-none lg:px-4 bg-gray-200 mt-2 lg:mt-8">
                        <CreditCardFormSection
                            check={check}
                            cardNumber={cardNumber}
                            cardName={cardName}
                            cardCvv={cardCvv}
                            cardMonth={cardMonth}
                            cardYear={cardYear}
                            onInputNumber={(e) => setCardNumber(cc_format(e.target.value.replace(/[^0-9]/g, '')))}
                            onInputName={(e) => setCardName(e.target.value)}
                            onInputCvv={(e) => setCardCvv(e.target.value.replace(/[^0-9]/g, ''))}
                            onInputMonth={(e) => setCardMonth(e.target.value.replace(/[^0-9]/g, ''))}
                            onInputYear={(e) => setCardYear(e.target.value.replace(/[^0-9]/g, ''))}
                            errorMessage={errorMessage}
                        />
                        <div className="p-4">
                            <p className="font-face-poppinsregular text-link_download lg:text-disclaimer text-gray-desc mb-4">
                                {t('credit_disclaimer')}
                            </p>
                        </div>
                        <div className="lg:h-5 h-32 bg-gray-200" />
                    </div>
                </div>
            </div>
            {showModalAlertError && (
                <ModalAlert text={t('failed_3')} text_button="OK" onClick={() => setShowModalAlertError(false)} />
            )}
        </>
    );
}

export default CreditCardPage;

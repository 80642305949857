// phone number formatter 62811 -> 0811
export const phoneNumber = (num) => {
    return num.replace(/^(08|628)/g, '628');
};

export const checkFormatPhone = (num) => {
    return /^(08|62)/.test(num);
}

export const normalizePhoneNumber = (num) => {
    return num.replace(/^(08|62)/, '');
}
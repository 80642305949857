import React from 'react';
import Failed from 'presentations/assets/images/failed.png';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';

// main component of maintenance page with image and text
function MaintenanceHero() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col md:w-1/2 items-center lg:mt-0 w-full h-auto lg:w-full md:mr-5 place-content-center mx-auto">
            <div className="h-48 lg:h-88 mx-auto">
                <img src={Failed} alt="Failed" className="h-40 lg:h-88" />
            </div>
            <p className="mt-6 text-lg lg:text-3xl font-face-tselbold text-center">{t('maintenance_title')}</p>
            <p className="mt-2 text-xs lg:text-base font-face-poppinsregular text-center lg:mt-2">
                {t('maintenance_subtitle')}
            </p>
        </div>
    );
}

export default MaintenanceHero;

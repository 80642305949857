import { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    copyToClipboard,
    DEFAULT_MINUTE,
    DEFAULT_SECOND,
    DEFAULT_HOUR,
    DEFAULT_HOUR_INDOMARET,
    DEFAULT_MINUTE_INDOMARET,
    DEFAULT_SECOND_INDOMARET,
    INDOMARET,
    setPartnerDisplayName,
} from 'utilities';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { useDispatch } from 'react-redux';
import { setLoadingState } from 'adapters';

export default function PaymentCodePageViewModel() {
    const { t } = useTranslation();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [hours, setHours] = useState();
    const dispatch = useDispatch();
    const [channel, setChannel] = useState('');
    const [showInstruction, setShowInstruction] = useState(false);
    const { state } = useLocation();
    const { code, total, detail, payment_info, app, redirect, failed } = state;
    let { merchant } = useParams();

    const isSecond = () => {
        let hour_in_second = Math.floor(payment_info.expiry_duration / 3600);
        let minute_in_second = Math.floor((payment_info.expiry_duration % 3600) / 60);
        let second_in_second = payment_info.expiry_duration - hour_in_second * 3600 - minute_in_second * 60;
        setHours(hour_in_second);
        setMinutes(minute_in_second);
        setSeconds(second_in_second);
    };

    const isMinute = () => {
        let hour_in_minute = Math.floor(payment_info.expiry_duration / 60);
        let minute_in_minute = Math.floor(payment_info.expiry_duration % 60);
        let second_in_minute = 0;
        setHours(hour_in_minute);
        setMinutes(minute_in_minute);
        setSeconds(second_in_minute);
    };

    const isHour = () => {
        let hour_in_hour = payment_info.expiry_duration;
        let minute_in_hour = 0;
        let second_in_hour = 0;
        setHours(hour_in_hour);
        setMinutes(minute_in_hour);
        setSeconds(second_in_hour);
    };

    const isDay = () => {
        let hour_in_day = payment_info.expiry_duration * 24;
        let minute_in_day = 0;
        let second_in_day = 0;
        setHours(hour_in_day);
        setMinutes(minute_in_day);
        setSeconds(second_in_day);
    };

    const isDefault = () => {
        if (merchant === INDOMARET) {
            setHours(DEFAULT_HOUR_INDOMARET);
            setMinutes(DEFAULT_MINUTE_INDOMARET);
            setSeconds(DEFAULT_SECOND_INDOMARET);
        } else {
            setHours(DEFAULT_HOUR);
            setMinutes(DEFAULT_MINUTE);
            setSeconds(DEFAULT_SECOND);
        }
    };

    // calculate remaining time based on expiry duration and duration unit
    useEffect(() => {
        dispatch(setLoadingState({ loading: false }));
        switch (payment_info.duration_unit) {
            case 'second':
                isSecond();
                break;
            case 'minute':
                isMinute();
                break;
            case 'hour':
                isHour();
                break;
            case 'day':
                isDay();
                break;
            default:
                isDefault();
        }
        // eslint-disable-next-line
    }, []);

    // set partner display name format
    useEffect(() => {
        setChannel(setPartnerDisplayName(payment_info.payment_provider, merchant));
    }, [merchant, payment_info.payment_provider]);

    // redirect to failed page if countdown finished

    // copy payment code and show toast
    const notify = () => {
        toast.info(t('copied_to_clipboard'), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        copyToClipboard(code);
    };

    const countHourOnly = useCallback(
        (interval) => {
            if (hours === 0) {
                clearInterval(interval);
            } else {
                setHours(hours - 1);
            }
        },
        [hours]
    );

    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                setMinutes(59);
                countHourOnly(interval);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [countHourOnly, minutes]
    );

    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    // perform countdown
    useEffect(() => {
        let myInterval = setInterval(() => {
            countDown(myInterval);
            // redirect to failed page if countdown finished
            if (hours === 0 && minutes === 0 && seconds === 0) {
                window.location.replace(failed);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, failed, hours, minutes, seconds]);

    return {
        total,
        detail,
        channel,
        hours,
        minutes,
        seconds,
        notify,
        code,
        payment_info,
        redirect,
        app,
        showInstruction,
        setShowInstruction,
    };
}

// define type of device based on user Agent
export const checkDevice = () => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent) ||
        (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.userAgent))
    ) {
        return 'Mobile';
    } else {
        return 'Desktop';
    }
};

// get params from url
export const getParams = () => {
    const query = new URLSearchParams(window.location.search);
    const partner = query.get('partner');
    const channel = query.get('channel');
    const token = query.get('token');
    const auth_code = query.get('auth_code') || query.get('authCode');
    console.log(window.location.search);
    return {
        partner: partner,
        channel: channel,
        token: token,
        auth_code: auth_code,
    };
};

// get params from url for dana direct debit
export const getDanaAuthParams = () => {
    const query = new URLSearchParams(window.location.search);
    const auth_code = query.get('auth_code');
    const channel = query.get('channel');

    return {
        auth_code: auth_code,
        channel: channel,
    };
};

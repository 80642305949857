import { LoadingPage } from '../pages';

export default function MandiriDDWco({
    binding_url,
    binding_code,
    request_id,
    binding_id,
    token_requestor_id,
    merchant_id,
    terminal_id,
    public_key,
    signature,
    lang,
}) {
    return (
        <div>
            <LoadingPage />
            <div className="hidden">
                <form name="frm_request" id="frm_request" action={binding_url} method="post">
                    <input name="jwt" value={binding_code} />
                    <input name="requestID" value={request_id} />
                    <input name="journeyID" value={binding_id} />
                    <input name="tokenRequestorID" value={token_requestor_id} />
                    <input name="merchantID" value={merchant_id} />
                    <input name="terminalID" value={terminal_id} />
                    <input name="language" value={lang} />
                    <input name="isBindAndPay" value="N" />
                    <input name="additionalData" value='{"userID":"ajiputra2.perkasa"}' />
                    <input name="publicKey" value={public_key} />
                    <input name="signature" value={signature} />
                    {/* <button type="submit" name="submit" value="Submit" /> */}
                    <button type="submit">
                        <p className="text-white text-base font-face-poppinsbold mx-auto">Loading...</p>
                    </button>
                </form>
            </div>
        </div>
    );
}

import React from 'react';
import ChevronUp from '../../../assets/images/up_chevron.svg';
import ChevronDown from '../../../assets/images/down_chevron.svg';

// payment category component
const PaymentCategory = (props) => {
    const { type, isExpand, setIsExpand } = props;
    return (
        <div
            className="flex w-full justify-between bg-gray-category pl-2 pr-4 py-2 rounded-t-2xl"
            onClick={() => setIsExpand(!isExpand)}
        >
            <p className="text-category pl-2 pr-2 pt-2 pb-2 font-face-poppinsbold">{type}</p>
            {isExpand ? (
                <img className="mr-2.5 w-4" src={ChevronUp} alt="Pembayaran" />
            ) : (
                <img className="mr-2.5  w-4" src={ChevronDown} alt="Pembayaran" />
            )}
        </div>
    );
};

export default PaymentCategory;

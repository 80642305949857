import React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Title } from './ComponentPage';

function TopBar({ text, onClick, partner, device }) {
    return (
        <div className="w-full h-12 py-2 px-2 lg:h-16 lg:py-4 lg:px-4 flex items-center relative">
            {/* if mobile device disable back arrow on payment list page */}
            {device === 'Mobile' ? null : (
                <div className="absolute left-4">
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onClick}>
                        <KeyboardArrowBackIcon fontSize={'inherit'} />
                        {/* <p className="text-base font-face-poppinsregular ml-1">Kembali</p> */}
                    </IconButton>
                </div>
            )}
            <div className="w-full">
                {/* <p className="font-face-tselbold mx-auto text-center text-topbar">{Title}</p> */}
                <Title partner={partner} text={text} />
            </div>
        </div>
    );
}

export default TopBar;

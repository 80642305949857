import { useSelector } from 'react-redux';
import VoucherBinding from './Components/VoucherBinding';
import VoucherNotFound from './Components/VoucherNotFound';
import { useState } from 'react';

export default function VoucherPageViewModel() {
    const [gopay, setGopay] = useState('');
    const {
        upp_data: { total_price, promo },
    } = useSelector((state) => state);

    const getPromo = (data) => {
        const dataPromo = { usable: [], unUsable: [] };
        data?.map((data, i) => {
            if (data?.minimum_applicable_amount <= total_price) {
                return dataPromo.usable.push(data);
            } else {
                return dataPromo.unUsable.push(data);
            }
        });
        return dataPromo;
    };

    const statusGopayBinding = (body) => {
        if (body?.recommendation_payment?.filter((data) => data?.partner_provider === 'gopay').length > 0) {
            setGopay(
                body?.recommendation_payment?.filter((data) => data?.partner_provider === 'gopay')[0]?.bind_info
                    ?.status === 'binding'
            );
        } else if (body?.list_payment_partner?.filter((data) => data?.partner_provider === 'gopay').length > 0) {
            setGopay(
                body?.list_payment_partner?.filter((data) => data?.partner_provider === 'gopay')[0]?.bind_info
                    ?.status === 'binding'
            );
        } else {
            setGopay(false);
        }
        console.log('showBind');
        console.log(gopay);
        return gopay;
    };

    const VoucherEmpty = ({ body, showBind, changePayment }) => {
        if (!statusGopayBinding(body)) {
            return <VoucherNotFound showBind={showBind} changePayment={changePayment} />;
        }
        if (getPromo(body.payment_promo)?.usable.length === 0 && getPromo(body.payment_promo)?.unUsable.length === 0) {
            return <VoucherBinding />;
        }
        return null;
    };

    return {
        getPromo,
        total_price,
        promo,
        statusGopayBinding,
        VoucherEmpty,
    };
}

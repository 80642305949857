import React from 'react';
import Warning from 'presentations/assets/images/warning.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

function ModalSelectPayment({ onClose, onContinue, payment }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full mx-auto lg:max-w-none">
                    {/*content*/}
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="rounded-xl h-0.5 w-24 bg-gray-pull mx-auto lg:hidden hover:bg-gray-700"
                            onClick={onClose}
                        />
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*header*/}
                        <div className="flex flex-row items-center justify-center p-5 rounded-t">
                            <img src={Warning} alt="Warning" />
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto">
                            <p className="text-lg font-face-tselbold mb-4">{t('payment_change')}</p>
                            <p className="text-sm text-gray-desc font-face-poppinsregular">
                                {t('gopay_payment_change')}
                            </p>
                        </div>
                        {/*footer*/}
                        <div className="flex justify-around items-center mt-6">
                            <button
                                className="border-red-tsel mx-2 border w-1/2 text-red-tsel active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onClose}
                            >
                                {t('cancel')}
                            </button>
                            <button
                                className="bg-red-tsel mx-2 w-1/2 text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onContinue}
                            >
                                {`${t('change')} ${payment}`}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-blue-dark_blue" onClick={onClose}></div>
        </>
    );
}

export default ModalSelectPayment;

import React from 'react';

function CustomerDetail({ number, nomor }) {
    return (
        <div className="bg-white items-center lg:py-6 px-5 py-5 flex flex-row justify-between h-auto w-full">
            <p className="text-sm font-face-poppinsregular">{nomor}</p>
            <p className="text-sm font-face-poppinsbold">{number}</p>
        </div>
    );
}

export default CustomerDetail;

import React from 'react';
import useViewModel from './SuccessBindingPageViewModel';
import BindingInfo from 'presentations/shared/BindingInfo';

// success binding page, can redirect to landing page with stored token
export default function SuccessBindingPage() {
    const { params, goToPreviousPath, redirectToPaymentPage } = useViewModel();

    return (
        <BindingInfo
            goToPreviousPath={goToPreviousPath}
            redirectToPaymentPage={redirectToPaymentPage}
            params={params}
            type={'success'}
        />
    );
}

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

function ModalOTP({ onClose, submitOTP, resendOTP }) {
    const [OTP, setOTP] = useState('');
    const { t } = useTranslation();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    useEffect(() => {
        setMinutes(3);
        setSeconds(0);
        // eslint-disable-next-line
    }, []);

    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                clearInterval(interval);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [minutes]
    );
    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    useEffect(() => {
        let myInterval = setInterval(() => {
            countDown(myInterval);
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, minutes, seconds]);

    const handleResendOTP = () => {
        resendOTP();
        setMinutes(0);
        setSeconds(10);
    };

    return (
        <>
            <div className=" justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full mx-auto lg:max-w-none">
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>

                        <div className="relative flex-auto w-full lg:w-auto mt-5">
                            <p className="text-base font-face-tselbold">{t('OTP_verification_word_1')}</p>
                            <p className="mt-4 text-sm font-face-poppinsregular">{t('OTP_verification_word_2')}</p>
                            <div className=" mt-5">
                                <p className="font-face-poppinsregular text-xs">{t('verification_code')}</p>
                            </div>
                            <div className="">
                                <input
                                    type="text"
                                    className="w-full border-2 border-gray-textinputborder focus:outline-none text-xs lg:text-sm px-2 py-2 mt-2"
                                    placeholder={t('OTP_placeholder')}
                                    maxLength="6"
                                    value={OTP}
                                    onInput={(e) => setOTP(e.target.value)}
                                />
                            </div>
                            {/* <div className=" flex justify-center content-center items-center mt-5">
                                <p className="font-face-poppinsregular text-xs">{t('verification_code')}</p>
                            </div>
                            <OTPInput
                                disabled={false}
                                value={OTP}
                                OTPLength={6}
                                otpType="any"
                                onChange={setOTP}
                                inputStyles={{
                                    background: '#F5F5F5',
                                    borderBottomWidth: 0.5,
                                    borderColor: '#414a4c',
                                    margin: 3,
                                    fontWeight: 'bold',
                                    fontSize: 24,
                                }}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            /> */}

                            {minutes === 0 && seconds === 0 ? (
                                <div className="flex items-center justify-center">
                                    <button
                                        type="button"
                                        className=" mt-5 w-1/4 font-face-poppinsregular font-bold shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-blue-link text-xs"
                                        onClick={handleResendOTP}
                                    >
                                        {'Resend OTP'}
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <div className=" flex justify-center content-center items-center mt-5">
                                        <p className=" text-payment_code_title font-face-poppinsregular">
                                            {t('OTP_verification_word_3')}
                                            <span className="font-bold ">
                                                {t('OTP_verification_word_4')}
                                                {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </span>
                                        </p>
                                    </div>
                                    <div className=" flex items-center justify-center mb-5"></div>
                                </>
                            )}
                        </div>

                        <div className={'flex items-center flex-col mt-6'}>
                            <button
                                disabled={OTP.length < 6}
                                type="button"
                                className={
                                    OTP.length < 6
                                        ? 'bg-gray-200 w-full text-gray-500 active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
                                        : 'bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
                                }
                                onClick={() => {
                                    submitOTP(OTP);
                                }}
                            >
                                {t('verify')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-blue-dark_blue" onClick={onClose}></div>
        </>
    );
}

export default ModalOTP;

import React from 'react';
import { useTranslation } from 'react-i18next';

const LoadingComponent = () => {
    const { t } = useTranslation();

    return (
        <div
            className="content"
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(100, 100, 100, 0.5)',
            }}
        >
            {/* <div className="spinner-border spinner-border-sm"></div> */}
            <div className="lds_ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="lg:text-sm text-xs text-[#173647] mt-1 lg:mt-3">{t('loading_1')}</div>
        </div>
    );
};

export default LoadingComponent;

import React from 'react';
import 'presentations/assets/css/app.css';

function ProductDetailPopUp({ items, onClick, textButton }) {
    return (
        <div className="bg-white items-center p-4 flex flex-row justify-between w-full lg:mt-3">
            <h2 className="font-face-poppinsbold text-left text-product_name">{items.item_name}</h2>
            {items.additional_detail ? (
                <button onClick={onClick}>
                    <p className="underline font-face-poppinsbold text-blue-link text-link text-right">{textButton}</p>
                </button>
            ) : null}
        </div>
    );
}

export default ProductDetailPopUp;

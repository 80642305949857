import React from 'react';

function PaymentCountDown({ hours, minutes, seconds, text }) {
    return (
        <div className=" bg-white w-full h-auto p-4 flex flex-row items-center">
            <p className="text-yellow_countdown text-gray-hero_title font-face-poppinsregular mr-1">{text}</p>
            <p className=" text-yellow_countdown text-yellow-countdown font-face-poppinsbold">
                {hours < 10 ? `0${hours}` : hours} : {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                {seconds < 10 ? `0${seconds}` : seconds}
            </p>
        </div>
    );
}

export default PaymentCountDown;

import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header, MerchantBarcodeInfo, PaymentCodeInstructionSection, ProductDetail } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import useViewModel from './PaymentCodePageViewModel';
import { price } from 'utilities';

// payment code page for merchant and virtual_account
function PaymentCodePage() {
    const { t } = useTranslation();
    const {
        total,
        detail,
        channel,
        hours,
        minutes,
        seconds,
        code,
        payment_info,
        notify,
        redirect,
        app,
        showInstruction,
        setShowInstruction,
    } = useViewModel();

    const handleCollapseInstruction = () => {
        setShowInstruction((prev) => !prev);
    };

    return (
        <div className="flex flex-grow h-full lg:min-h-screen md:h-full bg-gray-200 w-full lg:px-96 xl:px-98">
            <div className="h-full w-full pt-12 lg:w-full">
                <MerchantBarcodeInfo
                    total={total}
                    channel={channel}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    notify={notify}
                    code={code}
                    fee={detail.item_fee ? detail.item_fee : 0}
                />
                <div className="w-full">
                    <div className="-mt-2 lg:mt-4 md:mt-0">
                        <ProductDetail items={detail} />
                    </div>
                    <p className="text-category text-gray-hero_title px-4 pt-6 pb-3 font-face-poppinsbold">
                        {t('payment_instruction')}
                    </p>
                    <PaymentCodeInstructionSection
                        payment_info={payment_info}
                        partner={channel}
                        show={showInstruction}
                        handleCollapse={() => handleCollapseInstruction()}
                    />
                    <div className="bg-white h-auto w-full flex flex-wrap content-center py-5 px-4 mt-2">
                        <button
                            className="border-red-tsel border rounded-3xl w-full h-auto bg-red-tsel p-4 hover:bg-red-500"
                            onClick={() => {
                                window.location.replace(redirect);
                            }}
                        >
                            <p className="text-white text-button font-face-poppinsbold">{t('back_to_shopping')}</p>
                        </button>
                    </div>
                </div>
            </div>
            <Header channel={app} />
            <ToastContainer />
        </div>
    );
}

export default PaymentCodePage;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'utilities/i18n';
import { getParams } from 'utilities';
import { useDispatch } from 'react-redux';
import { setLoadingState } from 'adapters';
import Cookies from 'js-cookie';

export default function SuccessBindingPageViewModel() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(setLoadingState({ loading: false }));
        setParams(getParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToPreviousPath = () => {
        navigate(-1);
    };

    // open landing page with stored token from params or localstorage after getting binding status (success / failed)
    const redirectToPaymentPage = () => {
        dispatch(setLoadingState({ loading: true }));
        navigate({
            pathname: '/',
            // search: `?token=${params.token !== null ? params.token : Cookies.get('upp_token')}`,
        });
    };

    return {
        params,
        goToPreviousPath,
        redirectToPaymentPage,
    };
}

import React from 'react';

export default function BuyPackageButton(props) {
    const { isBalance, redirectToPurchaseBalance, t } = props;

    if (isBalance) {
        return (
            <button
                className="border-red-tsel border mt-0.75 w-full text-red-tsel active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={redirectToPurchaseBalance}
            >
                {t('recharge')}
            </button>
        );
    }
    return null;
}

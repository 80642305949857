import React from 'react';

import GopayRounded from 'presentations/assets/images/gopay-rounded.png';
import DanaRounded from 'presentations/assets/images/atom/icon/system/24/dana.png';
import { DANA, GOPAY } from 'utilities';

export const ModalBindingOfferLogo = (props) => {
    const { partner } = props;
    switch (partner) {
        case GOPAY:
            return <img src={GopayRounded} width="66.67" height="66.67" className="ml-1" alt="logo_gopay" />;
        case DANA:
            return <img src={DanaRounded} width="66.67" height="66.67" className="ml-1" alt="logo_gopay" />;
        default:
            return <div className="max-h-10 lg:max-h-7 w-auto bg-white" />;
    }
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from 'presentations/assets/translations/en/translations';
import { TRANSLATIONS_ID } from 'presentations/assets/translations/id/translations';

// localization using i18next
i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            id: {
                translation: TRANSLATIONS_ID,
            },
        },
    });

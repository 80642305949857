import React from 'react';
import 'presentations/assets/css/app.css';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScanQRIS from 'presentations/assets/images/ScanQRIS.png';
import { JSONInstructionScanQRIS } from 'presentations/assets/JSONAsset';
import i18next from 'i18next';

export default function ScanQRISInstruction({ text, show, handleCollapse }) {
    return (
        <>
            <button className="w-full h-auto" onClick={() => handleCollapse()}>
                <div className="bg-white items-center px-4 py-3 flex flex-row justify-between lg:mt-0">
                    <h2 className="font-face-poppinsbold text-left text-xs text-gray-hero_title">{text}</h2>
                    {show ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </div>
            </button>

            <Collapse in={show}>
                <div className="bg-white p-4">
                    {/* {i18next.language === 'en' ? (
                        <div> */}
                    {(i18next.language === 'en'
                        ? JSONInstructionScanQRIS.data.instruction_en
                        : JSONInstructionScanQRIS.data.instruction_id
                    ).map((question) => {
                        return (
                            <div key={question.key}>
                                <div className="flex flex-row h-auto w-full justify-between mb-2">
                                    <div className="w-1/12 lg:w-6">
                                        <p className="text-link_download font-face-poppinsregular text-center text-gray-hero_title">
                                            {question.key}.
                                        </p>
                                    </div>
                                    <div className="w-11/12 lg:mr-5">
                                        <p className="text-link_download font-face-poppinsregular text-gray-hero_title">
                                            {question.description}
                                        </p>
                                    </div>
                                </div>
                                {question.image ? <img src={ScanQRIS} alt="Download QRIS" className="mb-4" /> : null}
                            </div>
                        );
                    })}
                    {/* </div>
                    ) : (
                        <div>
                            {JSONInstructionScanQRIS.data.instruction_id.map((question, index) => {
                                return (
                                    <div key={index}>
                                        <div className="flex flex-row h-auto w-full justify-between mb-2">
                                            <div className="w-1/12 lg:w-6">
                                                <p className="text-link_download font-face-poppinsregular text-center text-gray-hero_title">
                                                    {question.key}.
                                                </p>
                                            </div>
                                            <div className="w-11/12 lg:mr-5">
                                                <p className="text-link_download font-face-poppinsregular text-gray-hero_title">
                                                    {question.description}
                                                </p>
                                            </div>
                                        </div>
                                        {question.image ? (
                                            <img src={ScanQRIS} alt="Download QRIS" className="mb-4" />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    )} */}
                </div>
            </Collapse>
        </>
    );
}

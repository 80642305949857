import React from 'react';

function FooterWeb({ text }) {
    return (
        <div className="h-auto bg-transparent w-full flex place-content-center py-4">
            {/* <div className="h-auto bg-gray-200 w-full flex place-content-center pt-1 pb-2"> */}
            <p className="text-sm font-face-poppinsregular mx-auto text-center">{text}</p>
        </div>
    );
}

export default FooterWeb;

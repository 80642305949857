import { fetchApi, fetchApiGet } from 'utilities';

// update Payment to Elisa
export async function updatePayment(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/elisa/payment', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}


// inquiry payment bca dd
export async function inquiryBCADD(payment_provider, auth) {
    try {
        const result = await fetchApiGet(
            'GET',
            process.env.REACT_APP_API_HOST,
            auth,
            `/upp/v1/inquiry-phone/${payment_provider}`
        );
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}


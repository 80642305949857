import React from 'react';
import useViewModel from './InboxPaymentCodePageViewModel';
import { DownloadQRISInstruction, PaymentCodeInstructionSection, ScanQRISInstruction } from 'presentations/shared';
import { QRIS } from 'utilities';
import { useTranslation } from 'react-i18next';

function InstructionSection() {
    const {
        data,
        partner,
        showDownloadInstruction,
        setShowDownloadInstruction,
        showScanInstruction,
        setShowScanInstruction,
        showInstruction,
        setShowInstruction,
    } = useViewModel();

    const { t } = useTranslation();
    const isQRIS = () => {
        return data.payment_info && data.payment_info.payment_provider === QRIS;
    };

    const handleCollapseDownloadInstruction = () => {
        setShowDownloadInstruction((prev) => !prev);
    };

    const handleColllapseScanInstruction = () => {
        setShowScanInstruction((prev) => !prev);
    };

    const handleCollapseInstruction = () => {
        setShowInstruction((prev) => !prev);
    };
    return (
        <>
            {isQRIS ? (
                <>
                    <div className="mt-3">
                        <DownloadQRISInstruction
                            text={t('how_to_download_qris')}
                            show={showDownloadInstruction}
                            handleCollapse={() => handleCollapseDownloadInstruction()}
                        />
                    </div>
                    <div className="mt-3">
                        <ScanQRISInstruction
                            text={t('how_to_scan_qris')}
                            show={showScanInstruction}
                            handleCollapse={() => handleColllapseScanInstruction()}
                        />
                    </div>
                </>
            ) : (
                <PaymentCodeInstructionSection
                    payment_info={data.payment_info ? data.payment_info : {}}
                    partner={partner}
                    show={showInstruction}
                    handleCollapse={() => handleCollapseInstruction()}
                />
            )}
        </>
    );
}

export default InstructionSection;

import React from 'react';
import { PartnerHeader, TopBar } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import Verified from 'presentations/assets/images/verification.png';
import Failed from 'presentations/assets/images/failed.png';

// success/failed binding page, can redirect to landing page with stored token
export default function BindingInfo({ params, goToPreviousPath, redirectToPaymentPage, type }) {
    const { t } = useTranslation();

    const renderInfo = () => {
        if (type === 'success') {
            return (
                <>
                    <p className="font-face-tselbold text-center text-base">
                        {params.partner}
                        {t('account_success_binding_1')}
                    </p>
                    <p className="mt-2 font-face-poppinsregular text-xs text-center">
                        {params.partner}
                        {t('account_success_binding_2')}
                    </p>
                </>
            );
        }
        return (
            <>
                <p className="font-face-tselbold text-center text-base">
                    {t('account_failed_binding')}
                    {params.partner}
                </p>
                <p className="mt-2 font-face-poppinsregular text-xs text-center">
                    {params.partner + t('account_failed_binding_2')}
                </p>
            </>
        );
    };

    return (
        <>
            <div className="flex flex-col flex-grow h-screen bg-white lg:bg-gray-200 lg:px-72">
                <TopBar partner={params.partner} onClick={goToPreviousPath} />
                <div className="h-screen flex flex-col w-full lg:justify-between">
                    <div className="bg-white">
                        <div className="px-8 lg:px-20 py-4 lg:pb-6 ">
                            <PartnerHeader channel={params.channel} partnerlogo={params.partner} />
                        </div>
                        <div className="h-32 w-32 lg:h-88 lg:w-88 mx-auto lg:mt-4 bg-white mt-14">
                            <img
                                src={type === 'success' ? Verified : Failed}
                                alt={type === 'success' ? 'Verified' : 'Failed'}
                            />
                        </div>
                        <div className="mt-6 lg:pb-8 px-8">{renderInfo()}</div>
                    </div>
                    <div className="bg-white h-22 w-full py-5 px-4 lg:px-20 mt-9">
                        <button
                            type="submit"
                            className="rounded-3xl w-full mx-auto flex bg-red-tsel py-4 lg:py-5 px-5 hover:bg-red-500"
                            onClick={redirectToPaymentPage}
                        >
                            <p className="text-white text-button font-face-poppinsbold mx-auto">
                                {t('back_to_payment_method')}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

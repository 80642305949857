import React from 'react';
import 'presentations/assets/css/app.css';
import { useSelector } from 'react-redux';

function Header({ channel }) {
    const stateGlobal = useSelector((state) => state);

    const redirectToHomePage = () => {
        if (stateGlobal.upp_data.home_url && stateGlobal.upp_data.home_url !== '') {
            window.location.assign(stateGlobal.upp_data.home_url);
        }
    };

    return (
        <div className="bg-white h-auto lg:h-auto w-full border-b-2 px-4 py-5 lg:px-36 lg:py-6 items-center shadow-sm  fixed top-0 left-0 flex z-10">
            <div className="h-auto  flex w-full items-center max-h-16">
                <button onClick={redirectToHomePage} className="bg-transparent">
                    <img
                        src={`${process.env.REACT_APP_IMAGE_HOST}/public/logo/${channel}.png`}
                        alt="Logo"
                        className={`${channel === 'i1' || channel === 'a0' ? 'w-28' : 'w-16'} bg-transparent`}
                    />
                </button>
            </div>
        </div>
    );
}

export default Header;

// default expiry value for payment code and qris
export const DEFAULT_MINUTE = 0;
export const DEFAULT_SECOND = 0;
export const DEFAULT_HOUR = 24;
export const DEFAULT_HOUR_INDOMARET = 0;
export const DEFAULT_MINUTE_INDOMARET = 15;
export const DEFAULT_SECOND_INDOMARET = 0;
export const DEFAULT_INHOUR_INDOMARET = 0.25;

export const DEFAULT_MINUTE_MANDIRIDD = 3;
export const DEFAULT_SECOND_MANDIRIDD = 0;

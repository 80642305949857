import { FINNET_VA, MCP_VA, VIRTUAL_ACCOUNT, XENDIT_INVOICES } from './partnerConstants';

const isVirtualAccount = (payment_partner) => {
    return (
        payment_partner === VIRTUAL_ACCOUNT ||
        payment_partner === FINNET_VA ||
        payment_partner === MCP_VA ||
        payment_partner === XENDIT_INVOICES
    );
};

export const setPartnerDisplayName = (partner, merchant) => {
    if (isVirtualAccount(partner)) {
        return merchant.toUpperCase();
    } else {
        return merchant.charAt(0).toUpperCase() + merchant.slice(1);
    }
};

// pricing based on list_payment_partner params
export function setPricing(partner, body, external_payment, list_payment_partner) {
    // set item_price as value
    let total = body.item_details.item_price;

    const externalPaymentPricing = () => {
        let partnerListExternal = external_payment.filter((word) => word.partner_provider === partner);
        if (partnerListExternal.length !== 0) {
            if (partnerListExternal[0].price && partnerListExternal[0].price !== 0) {
                total = partnerListExternal[0].price;
            }
        }
    };

    const partnerPricing = () => {
        let partnerList = list_payment_partner.filter((word) => word.partner_provider === partner);
        if (partnerList[0].price && partnerList[0].price !== 0) {
            total = partnerList[0].price;
        }
    };

    const virtualAccountPricing = () => {
        let bankList = body.list_bank.filter((word) => word.bank_name === partner);
        if (bankList[0].price && bankList[0].price !== 0) {
            total = bankList[0].price;
        }
    };

    const isExternalPartner = () => {
        return (
            external_payment !== null && external_payment.filter((word) => word.partner_provider === partner).length > 0
        );
    };

    const isListPaymentPartner = () => {
        return (
            list_payment_partner !== null &&
            list_payment_partner.filter((word) => word.partner_provider === partner).length > 0
        );
    };

    const isVirtualAccount = () => {
        return body.list_bank && body.list_bank.filter((word) => word.bank_name === partner).length > 0;
    };

    if (partner !== 'balance') {
        if (isExternalPartner()) {
            // set price value as external partner price value
            externalPaymentPricing();
        } else if (isListPaymentPartner()) {
            // set price value as partner price value
            partnerPricing();
        } else if (isVirtualAccount()) {
            // set price value as list_bank virtual account price value
            virtualAccountPricing();
        }
    }
    return total;
}

import Cookies from 'js-cookie';
// get token from url params
export const getToken = () => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token') ? query.get('token') : Cookies.get('upp_token');
    const session_id = query.get('session_id') ? query.get('session_id') : '';

    console.log('query ' + query);
    console.log('token ' + token);
    if (token) {
        return token;
    } else if (session_id) {
        return session_id;
    }
};

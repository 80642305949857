import React from 'react';
import HeroVoucherBinding from 'presentations/assets/images/voucher_binding.svg';
import { useTranslation } from 'react-i18next';

export default function VoucherNotFound({ showBind, changePayment }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col justify-center items-center h-1/2">
                <img className="w-32" src={HeroVoucherBinding} alt="Silahkan binding akun untuk menggunakan Voucher" />
                <h3 className="font-face-tselbold mt-8 mx-auto text-center text-base">
                    {t('gopay_binding_wording_3')}
                </h3>
                <p className="font-face-poppinsregular font-normal text-center text-xs">
                    {t('gopay_binding_wording_4')}
                </p>
                <button
                    className="rounded-3xl bg-red-tsel mt-8 p-3 hover:bg-red-500"
                    onClick={() => {
                        changePayment({ target: { value: 'gopay' } });
                        showBind(true);
                    }}
                >
                    <p className="text-white text-button font-face-poppinsregular">{t('connectpromo')}</p>
                </button>
            </div>
        </>
    );
}

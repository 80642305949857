import { t } from 'i18next';

// partner id constant value
export const LINKAJA = 'linkaja';
export const LINKAJA_APP = 'linkaja_app';
export const LINKAJA_WCO = 'linkaja_wco';
export const OVO = 'ovo';
export const DANA = 'dana';
export const SHOPEEPAY = 'shopeepay';
export const QRIS = 'qris';
export const GOPAY = 'gopay';
export const AKULAKU = 'akulaku';
export const KREDIVO = 'kredivo';
export const CREDIT_CARD = 'credit_card';
export const BRI_DD = 'bri_dd';
export const FINNET_CC = 'finnet_cc';
export const MCP_CC = 'mcp_cc';
export const INDOMARET = 'indomaret';
export const ALFAMART = 'alfamart';
export const VIRTUAL_ACCOUNT = 'virtual_account';
export const FINNET_VA = 'finnet_va';
export const MCP_VA = 'mcp_va';
export const MANDIRI_DD = 'mandiri_dd';
export const BCA_DD = 'bca_dd';
export const BNI_DD = 'bni_dd';
export const XENDIT_INVOICES = 'xendit_invoices';
export const BSI = 'bsi';
export const BTN = 'btn';
export const BNC = 'bnc';
export const BJB = 'bjb';
export const NAGARI = 'nagari';
export const MEGA = 'mega';

// partner category constant value
export const EMONEY = 'E-Money';
export const PAYLATER = 'Paylater';
export const MERCHANT = 'Merchant';
export const CREDITCARD = 'Credit Card';
export const DIRECT_DEBIT = 'Direct Debit';
export const Virtual_Account = 'Virtual Account';

export const CATEGORY = {
    'E-Money': {
        section: EMONEY,
        payment: 'emoney',
    },
    Paylater: {
        section: PAYLATER,
        payment: 'paylater',
    },
    Merchant: {
        section: MERCHANT,
        payment: 'merchant',
    },
    'Credit Card': {
        section: CREDITCARD,
        payment: 'cc',
    },
    'Direct Debit': {
        section: DIRECT_DEBIT,
        payment: 'direct_debit',
    },
    'Virtual Account': {
        section: Virtual_Account,
        payment: 'va',
    },
};

export const VA = 'VA';
export const CC = 'CC';
export const XENDIT_WALLET = 'xendit_wallet';
export const FINNET_WALLET = 'finnet_wallet';

// aggregator partner
export const AGGREGATOR_PARTNER = ['virtual_account', 'finnet_va', 'mcp_va', 'xendit_invoices'];

import { DANA, GOPAY, QRIS, SHOPEEPAY, XENDIT_WALLET } from 'utilities';

// set body to be sent to payment API based on selected partner
export function setBody(body, event, deviceType, compareLinkAja) {
    const _tempData = body;
    if (_tempData.payment_info.expiry_duration) {
        delete _tempData.payment_info.expiry_duration;
    }

    if (_tempData.payment_info.duration_unit) {
        delete _tempData.payment_info.duration_unit;
    }
    if (_tempData.payment_info.promotion_id && event.target.value.split('|')[0] !== GOPAY) {
        delete _tempData.payment_info.promotion_id;
    }

    if (event.target.value.split('|').length > 1 && event.target.value.split('|')[1].trim() !== '') {
        if (
            event.target.value.split('|')[0] === XENDIT_WALLET &&
            body.list_payment_partner.filter((item) => item.partner_provider === XENDIT_WALLET)[0].bind_info.status ===
                'binding'
        ) {
            _tempData.payment_info.payment_provider = event.target.value.split('|')[0];
            _tempData.payment_info.bank_name = body.list_payment_partner.filter(
                (item) => item.partner_provider === event.target.value.split('|')[0]
            )[0].bank_name;
        } else if (
            body.list_payment_partner &&
            body.list_payment_partner.filter((item) => item.bank_name === event.target.value.split('|')[1]).length !==
                0 &&
            body.list_payment_partner.filter(
                (item) =>
                    item.bank_name === event.target.value.split('|')[1] &&
                    item.partner_provider === event.target.value.split('|')[0]
            )[0].partner_provider
        ) {
            // handling virtual account payment with selected bank
            _tempData.payment_info.payment_provider = body.list_payment_partner.filter(
                (item) =>
                    item.bank_name === event.target.value.split('|')[1] &&
                    item.partner_provider === event.target.value.split('|')[0]
            )[0].partner_provider;
            // append selected bank_name to body
            _tempData.payment_info.bank_name = event.target.value.split('|')[1];
        } else if (
            body.list_bank &&
            body.list_bank.filter((item) => item.bank_name === event.target.value.split('|')[1]).length !== 0
        ) {
            // handling virtual account payment with selected bank
            _tempData.payment_info.payment_provider = body.list_bank.filter(
                (item) => item.bank_name === event.target.value.split('|')[1]
            )[0].partner_provider;
            // append selected bank_name to body
            _tempData.payment_info.bank_name = event.target.value.split('|')[1];
        }
    } else {
        _tempData.payment_info.payment_provider = event.target.value.split('|')[0];
        switch (true) {
            case event.target.value.split('|')[0] === QRIS:
                // if qris selected, as IFA fill bank_name with 'gopay'
                _tempData.payment_info.bank_name = GOPAY;
                break;
            case event.target.value.split('|')[0] === GOPAY &&
                ((body.list_payment_partner.filter((item) => item.partner_provider === GOPAY).length > 0 &&
                    body.list_payment_partner
                        .filter((item) => item.partner_provider === GOPAY)[0]
                        .method.includes('direct_debit')) ||
                    (body.recommendation_payment.filter((item) => item.partner_provider === GOPAY).length > 0 &&
                        body.recommendation_payment
                            .filter((item) => item.partner_provider === GOPAY)[0]
                            .method.includes('direct_debit'))):
                if (
                    (body.list_payment_partner.filter((item) => item.partner_provider === GOPAY).length > 0 &&
                        body.list_payment_partner.filter((item) => item.partner_provider === GOPAY)[0].bind_info
                            .status === 'binding') ||
                    (body.recommendation_payment.filter((item) => item.partner_provider === GOPAY).length > 0 &&
                        body.recommendation_payment.filter((item) => item.partner_provider === GOPAY)[0].bind_info
                            .status === 'binding')
                ) {
                    // if gopay selected, and binding status is active, fill payment_id with customer phone number
                    _tempData.payment_info.payment_id = body.customer_details.cust_phone;
                    _tempData.payment_info.bank_name = 'binding';
                } else {
                    _tempData.payment_info.payment_id = '';
                    _tempData.payment_info.bank_name = '';
                }
                break;
            case event.target.value.split('|')[0] === SHOPEEPAY &&
                ((body.list_payment_partner.filter((item) => item.partner_provider === SHOPEEPAY).length > 0 &&
                    body.list_payment_partner
                        .filter((item) => item.partner_provider === SHOPEEPAY)[0]
                        .method.includes('direct_debit')) ||
                    (body.recommendation_payment.filter((item) => item.partner_provider === SHOPEEPAY).length > 0 &&
                        body.recommendation_payment
                            .filter((item) => item.partner_provider === SHOPEEPAY)[0]
                            .method.includes('direct_debit'))):
                if (
                    (body.list_payment_partner.filter((item) => item.partner_provider === SHOPEEPAY).length > 0 &&
                        body.list_payment_partner.filter((item) => item.partner_provider === SHOPEEPAY)[0].bind_info
                            .status === 'binding') ||
                    (body.recommendation_payment.filter((item) => item.partner_provider === SHOPEEPAY).length > 0 &&
                        body.recommendation_payment.filter((item) => item.partner_provider === SHOPEEPAY)[0].bind_info
                            .status === 'binding')
                ) {
                    // if gopay selected, and binding status is active, fill payment_id with customer phone number
                    _tempData.payment_info.payment_id = '';
                    _tempData.payment_info.bank_name = 'binding';
                    _tempData.payment_info.payment_invoice = null;
                } else {
                    _tempData.payment_info.payment_id = '';
                    _tempData.payment_info.bank_name = '';
                }
                break;
            case event.target.value.split('|')[0] === DANA &&
                body.list_payment_partner.filter((item) => item.partner_provider === DANA)[0]?.bind_info?.status ===
                    'binding':
                // if dana selected, and binding status is active, fill bank_name with 'binding'
                _tempData.payment_info.bank_name = 'binding';
                _tempData.payment_info.payment_id = '';
                break;
            default:
                // else, fill only payment_provider params
                _tempData.payment_info.bank_name = '';
                _tempData.payment_info.payment_id = '';
                break;
        }
    }
    return _tempData;
}

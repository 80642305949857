import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'utilities/i18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BRI_DD } from 'utilities';
import { requestOtpCode } from 'data';
import Connected from '../../../presentations/assets/images/success_hand.png';
import WarningLogo from '../../../presentations/assets/images/warning.png';
import FailedLogo from '../../../presentations/assets/images/failed_net.png';

export default function DirectDebitPageViewModel() {
    const [dataDirectDebit, setDataDirectDebit] = useState({
        card_number: '',
        card_validity_month: '',
        card_validity_year: '',
        phone_number: '',
    });
    const [modalWording, setModalWording] = useState({
        modalText: '',
        modalTitle: '',
        modalButton: '',
        modalImg: '',
        modalOnClick: '',
    });
    const { t } = useTranslation();
    const stateGlobal = useSelector((states) => states);
    const { state } = useLocation();
    let navigate = useNavigate();
    const { payment_info, customer_info } = state;
    const [number, setNumber] = useState('');
    const [agree, setAgree] = useState(false);
    const [close, setClose] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [buttonText, setButtonText] = useState(t('ok'));
    const [buttonCollor, setButtonCollor] = useState(t('bg-red-tsel'));
    // const className = `rounded-3xl w-full mx-auto flex ${buttonCollor} py-4 lg:py-5 px-5`;
    const [buttonTextCollor, setButtonTextCollor] = useState('text-white ');
    const scrollDistance = 500;
    const regex = /^(0[1-9]|1[0-2])$/;
    const [otpWarning, setOtpWarning] = useState('');

    const handleClick = () => {
        setButtonText(t('ok2'));
        setButtonCollor('bg-gray-300');
        setButtonTextCollor('text-gray');
        setAgree(true);
        window.scrollTo({ top: window.scrollY + scrollDistance, behavior: 'smooth' });
    };

    const handletoLP = () => {
        setShowModalAlert(false);
        if (close) {
            navigate(-1);
        }
    };
    const handleOtp = (alertValue, word) => {
        if (word === '') {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'connected',
                modalTitle: 'connected_word',
                modalButton: 'done',
                modalImg: Connected,
            });
            setClose(true);
        } else if (word.includes('Invalid Token')) {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'failed_wording',
                modalTitle: 'failed',
                modalButton: 're_input',
                modalImg: FailedLogo,
            });
        } else if (word.includes('Timeout to Worker')) {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'failed_wording',
                modalTitle: 'failed',
                modalButton: 're_input',
                modalImg: FailedLogo,
            });
        } else if (alertValue !== true) {
            setOtpWarning(word);
        }

        if (alertValue === false) {
            setShowOtp(true);
        }
    };

    const reqOtp = async (partner) => {
        let binding_body = {
            payment_provider: payment_info.payment_provider,
        };
        if (partner === BRI_DD) {
            binding_body = {
                payment_provider: partner,
                customer_phone: 62 + '' + dataDirectDebit.phone_number,
                card_number: dataDirectDebit.card_number,
                expiration_date: dataDirectDebit.card_validity_month + '' + dataDirectDebit.card_validity_year,
            };
        }
        if (partner === BRI_DD) {
            try {
                const response = await requestOtpCode(binding_body, stateGlobal.upp_data.token);
                if (response && response.status_code === '00') {
                    setShowOtp(true);
                } else if (response && response.data.status_desc.includes('Invalid Token')) {
                    setShowModalAlert(true);
                    setOtpWarning('');
                    setModalWording({
                        ...setModalWording,
                        modalText: 'failed_wording',
                        modalTitle: 'failed',
                        modalButton: 're_input',
                        modalImg: FailedLogo,
                    });
                } else if (response && response.data.status_desc.includes('Timeout to Worker')) {
                    setShowModalAlert(true);
                    setOtpWarning('');
                    setModalWording({
                        ...setModalWording,
                        modalText: 'failed_wording',
                        modalTitle: 'failed',
                        modalButton: 're_input',
                        modalImg: FailedLogo,
                    });
                } else if (response && response.data.payment_response.response_desc === 'Card Information Invalid') {
                    setShowModalAlert(true);
                    setModalWording({
                        ...setModalWording,
                        modalText: 'warningdata',
                        modalTitle: 'invalid_data',
                        modalButton: 'check_data',
                        modalImg: WarningLogo,
                    });
                } else if (response && response.data.payment_response.response_desc === 'Too Many Requests') {
                    setShowModalAlert(true);
                    setModalWording({
                        ...setModalWording,
                        modalText: 'three_times_wording',
                        modalTitle: 'three_times',
                        modalButton: 'wait',
                        modalImg: WarningLogo,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const initiateBinding = async () => {
        if (payment_info.payment_provider === BRI_DD) {
            reqOtp(payment_info.payment_provider);
        }
    };

    const goToPreviousPath = () => {
        navigate(-1);
    };

    return {
        // error,
        goToPreviousPath,
        customer_info,
        number,
        setNumber,
        showModalAlert,
        setShowModalAlert,
        showError,
        initiateBinding,
        payment_info,
        setDataDirectDebit,
        dataDirectDebit,
        agree,
        setAgree,
        showOtp,
        setShowOtp,
        setShowError,
        modalWording,
        setModalWording,
        handleClick,
        handleOtp,
        stateGlobal,
        buttonTextCollor,
        buttonText,
        regex,
        buttonCollor,
        otpWarning,
        reqOtp,
        handletoLP,
    };
}
